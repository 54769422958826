import React, { useEffect } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import _ from "lodash";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

import { GoogleAdSense, StaticImages } from "../../utils/constants";
import { playlistController } from "../../features/playlist/playlistSlice";
import { getConfig } from "../../services/apiUtils";
import PlaylistCard from "../_cards/PlaylistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { ROUTES } from "../../routes/constants";
import GoogleAd from "../Advertisements/GoogleAd";

function TrendingPlaylists({ display = 8, genreType = "all" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Select playlists and loading/error states from Redux
  const { adminPlaylists, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  // Fetch trending playlists on mount
  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          playlistController.getAllAdminPlaylists({
            payload: genreType !== "all" ? { genre: genreType } : {},
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch trending playlists:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch, genreType]);

  return (
    <div className="my-4">
      <div className="d-flex my-5">
        <h1 className="fw-bold">
          Trending {genreType !== "all" ? _.capitalize(genreType) : ""}{" "}
          Playlists
        </h1>
      </div>

      <Row className="g-4">
        {/* Playlist section */}
        <Col lg={9} md={9} sm={12} xs={12}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : adminPlaylists && adminPlaylists.length > 0 ? (
            <div>
              <Row className="p-4 g-4 rounded-4 bg-white">
                {adminPlaylists.slice(0, display).map((playlist, index) => {
                  return (
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <PlaylistCard
                        viewMode="grid"
                        playlist={playlist}
                        index={index}
                        key={index}
                        user="all"
                      />
                    </Col>
                  );
                })}
              </Row>
              <Col className="p-4">
                <div className="d-flex align-items-center">
                  <span className="fw-bold">See All</span>
                  <Button
                    variant="eigengrau"
                    size="sm"
                    className="fw-bold fs-6 mx-2 rounded-circle"
                    onClick={() => navigate(ROUTES.MAIN.PLAYLISTS)}
                  >
                    <MdArrowOutward />
                  </Button>
                </div>
              </Col>
            </div>
          ) : (
            <p className="text-center">
              No trending playlists available at the moment.
            </p>
          )}
        </Col>

        {/* Advertisement section */}
        <Col lg={3} md={3} sm={12} xs={12}>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_9_v}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TrendingPlaylists;
