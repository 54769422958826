import React from "react";
import { Collapse, Row, Col, Input, Button } from "antd";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { SearchOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

function HelpPage() {
  const seoData = getSEOConfig("helpPage");

  // FAQ items
  const faqs = [
    {
      question: "How can I create an account and start using the site?",
      answer:
        "To create an account, go to the 'Sign Up' page, fill in the required details, and submit the form. You will then be able to access our site's features.",
    },
    {
      question: "What types of content can I access on this website?",
      answer:
        "Our website provides access to a variety of music, articles, and community features to enhance your experience.",
    },
    {
      question:
        "Are there any costs associated with using the website's features?",
      answer:
        "Basic access is free, but we offer premium features and content through subscription plans.",
    },
    {
      question: "How is my personal information protected on the site?",
      answer:
        "We prioritize your privacy and use industry-standard security measures to protect your data.",
    },
    {
      question:
        "What should I do if I encounter a technical issue or need support?",
      answer:
        "If you experience any technical issues, please contact our support team at [support email] or use the contact form on our website. We strive to respond within 24-48 hours.",
    },
    {
      question:
        "Can I download or share the music and content from the website?",
      answer:
        "Content on our site is for streaming only. Downloading and sharing are restricted to protect copyright.",
    },
    {
      question: "What is your policy on user-generated content and comments?",
      answer:
        "We encourage positive engagement and expect users to follow community guidelines, avoiding offensive or inappropriate content.",
    },
  ];

  return (
    <MainLayout>
      {/* <SEOManager {...seoData} /> */}
      <div className="m-2">
        <TopBar />
      </div>

      <div className="m-4 m-lg-2 p-4">
        <h1 className="fw-bold mb-4">FAQs</h1>

        <Row gutter={[16, 16]}>
          {/* FAQ Section */}
          <Col lg={16} md={16} sm={24} xs={24}>
            <Collapse accordion bordered={false} className="bg-white">
              {faqs.map((faq, index) => (
                <Panel header={faq.question} key={index} showArrow={true}>
                  <p>{faq.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </Col>

          {/* Advertisement Section */}
          <Col lg={8} md={8} sm={24} xs={24} className="text-center">
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.help_1_v}
            />
          </Col>
        </Row>

        {/* Search Section */}
        {/* <div className="text-center bg-sliceHeaven text-white py-5 rounded-3">
          <div className="my-5">
            <h3>Can’t find what you’re looking for?</h3>
            <Row justify="center" gutter={16}>
              <Col xs={24} sm={20} md={16} lg={12}>
                <div className="d-flex justify-content-center">
                  <Input
                    placeholder="Ask your questions here"
                    suffix={
                      <Button
                        variant="solid"
                        color="default"
                        shape="circle"
                        onClick={() => {}}
                      >
                        <SearchOutlined />
                      </Button>
                    }
                    className="rounded-pill"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div> */}
      </div>
    </MainLayout>
  );
}

export default HelpPage;
