import {
  MdAlbum,
  MdLogout,
  MdOutlinePassword,
  MdOutlineSettings,
} from "react-icons/md";
import { ROUTES } from "../routes/constants";
import { FaMusic, FaUser } from "react-icons/fa6";
import { FaListAlt } from "react-icons/fa";

// App Static Links

export const StaticLinks = {
  App: {
    LyricFindLink: "https://www.lyricfind.com/",
    AppFacebookLink:
      "https://www.facebook.com/profile.php?id=61565559758909&mibextid=kFxxJD",
    AppInstagramLink:
      "https://www.instagram.com/lyricswebofficial?igsh=MTNvYWFpcW1kaWE5aA==",
  },
};
export const LyricFindLink = "https://www.lyricfind.com/";

export const GoogleAdSense = {
  client: "ca-pub-2299535349128385",
  slot: {
    home_1: "1749937304",
    home_2: "6425816970",
    home_3: "1407697460",
    home_4: "8707602983",
    home_5: "6125100850",
    home_6: "6623992059",
    home_7: "7937073727",
    home_8_v: "7429908899",
    home_9_v: "8504924017",
    about_us_1_v: "4734329264",
    contact_us_1_v: "8945037012",
    help_1_v: "3229675902",
    privacy_policy_1: "7954521062",
    privacy_policy_2: "3097881831",
    terms_of_use_1: "4002815289",
    terms_of_use_2: "1581552314",
    news_1: "4002334540",
    news_2: "8504924017",
    news_details_1_v: "8388006961",
    news_details_2: "9107321564",
    search_1: "6732176339",
    search_2: "7853686318",
    search_3: "2541913216",
    genres_1: "5965889579",
    genres_2: "9713562890",
    genres_3_v: "4776065017",
    filter_artists_1: "7158685779",
    filter_artists_2_v: "6376921290",
    playlists_1: "9334257126",
    playlists_2: "6157429443",
    profile_preview_1: "1129593347",
    profile_preview_2: "4820297046",
    profile_edit_1: "3507215377",
    profile_edit_2: "9881052034",
    contribute_lyrics_1: "3315643682",
    contribute_lyrics_2: "5750235332",
    albums_1: "8840863723",
    albums_2: "3284155415",
    songs_1: "7418841542",
    songs_2_v: "5479939880",
    songs_3_s: "4274932759",
    lyrics_1: "3205393665",
    lyrics_2_v: "7767455208",
    artists_1: "8952544367",
    artists_2_v: "3700217687",
    artist_bio_1: "6729442371",
    artist_bio_2_v: "8915756048",
    album_songs_1_v: "4589498227",
    playlist_songs_1_v: "5711008208",
    user_playlists_1_v: "3963678231",
  },
};

export const StaticImages = {
  App: {
    // App Logo & Static
    AppLogoLyrics: "/assets/static/AppLogoLW.svg",
    AppGoogle: "/assets/static/Google.svg",
    AppAuthGradientBackground: "/assets/static/AuthGradientBackground.png",
    ChromeIcon: "/assets/static/ChromeIcon.png",
    AppMaintenance: "/assets/static/AppMaintenance.jpg",
    AppDefault: "/assets/static/AppDefault.jpg",
    AppDefaultS: "/assets/static/AppLogoLW_1080_S.png",
    AppDefaultR: "/assets/static/AppLogoLW_1080_R.png",
    AppDefaultText: "/assets/static/AppTextLogoLW_1080.png",
    AppDefaultBlue: "/assets/static/AppLogoLW_Blue_1080.png",
    AppDefaultWhite: "/assets/static/AppLogoLW_White_1080.png",
  },
  Album: {
    Img1: "/assets/static/AlbumImg1.png",
    Img2: "/assets/static/AlbumImg2.png",
    Img3: "/assets/static/AlbumImg3.png",
    Img4: "/assets/static/AlbumImg4.png",
  },
  Banner: {
    AppHome: "/assets/static/AppHomeBanner.png",
  },
  FollowUs: {
    Img1: "/assets/static/FollowUsImg1.png",
    Img2: "/assets/static/FollowUsImg2.png",
    Img3: "/assets/static/FollowUsImg3.png",
    Img4: "/assets/static/FollowUsImg4.png",
    Img5: "/assets/static/FollowUsImg5.png",
    Img6: "/assets/static/FollowUsImg6.png",
    Img7: "/assets/static/FollowUsImg7.png",
    Img8: "/assets/static/FollowUsImg8.png",
    Img9: "/assets/static/FollowUsImg9.png",
  },
  GoogleAds: {
    DesktopAd_H_large: "/assets/static/DesktopAd_H_large.png",
    DesktopAd_H_small: "/assets/static/DesktopAd_H_small.png",
    DesktopAd_V: "/assets/static/DesktopAd_V.png",
    DesktopAd_S: "/assets/static/DesktopAd_S.png",
    MobileAd: "/assets/static/MobileAd.png",
    MobileAd_S: "/assets/static/MobileAd_S.png",
    MobileAd_H_small: "/assets/static/MobileAd_H_small.png",
  },
  News: {
    // News Images
    Img1: "/assets/static/NewsImg1.png",
    Img2: "/assets/static/NewsImg2.png",
    Img3: "/assets/static/NewsImg3.png",
    Img4: "/assets/static/NewsImg4.png",
  },
  Pages: {
    // News Detail Images
    NewsDetailsImg1: "/assets/static/NewsDetailsImg1.png",
    PlaylistCover: "/assets/static/PlaylistCover.png",
  },
  Podcasts: {
    // Podcasts Images
    Img1: "/assets/static/PodcastImg1.png",
    Img2: "/assets/static/PodcastImg2.png",
    Img3: "/assets/static/PodcastImg3.png",
    Img4: "/assets/static/PodcastImg4.png",
  },
  Utils: {
    MusicMasterQuizBg: "/assets/static/MusicMasterQuizBg.png",
  },
};

export const ServerErrorMessage =
  "Server Maintenance... Please Try Again Later";

export const mainNavLinks = [
  { label: "Artists", path: "artists", icon: <FaUser /> },
  { label: "Songs", path: "songs", icon: <FaMusic /> },
  { label: "Albums", path: "albums", icon: <MdAlbum /> },
  { label: "Playlists", path: "playlists", icon: <FaListAlt /> },
];

export const letterNavLinks = [
  { label: "A", path: "/artist/a" },
  { label: "B", path: "/artist/b" },
  { label: "C", path: "/artist/c" },
  { label: "D", path: "/artist/d" },
  { label: "E", path: "/artist/e" },
  { label: "F", path: "/artist/f" },
  { label: "G", path: "/artist/g" },
  { label: "H", path: "/artist/h" },
  { label: "I", path: "/artist/i" },
  { label: "J", path: "/artist/j" },
  { label: "K", path: "/artist/k" },
  { label: "L", path: "/artist/l" },
  { label: "M", path: "/artist/m" },
  { label: "N", path: "/artist/n" },
  { label: "O", path: "/artist/o" },
  { label: "P", path: "/artist/p" },
  { label: "Q", path: "/artist/q" },
  { label: "R", path: "/artist/r" },
  { label: "S", path: "/artist/s" },
  { label: "T", path: "/artist/t" },
  { label: "U", path: "/artist/u" },
  { label: "V", path: "/artist/v" },
  { label: "W", path: "/artist/w" },
  { label: "X", path: "/artist/x" },
  { label: "Y", path: "/artist/y" },
  { label: "Z", path: "/artist/z" },
  // { label: "#", path: "/#" },
];

export const dropdownItems = [
  {
    icon: <MdOutlineSettings className="fs-4 mx-2" />,
    label: "Profile",
    className: "",
    path: ROUTES.AUTH.PROFILE,
  },
  {
    icon: <MdOutlinePassword className="fs-4 mx-2" />,
    label: "Change Password",
    className: "",
    path: ROUTES.AUTH.CHANGE_PASSWORD,
  },
  {
    icon: <MdLogout className="fs-4 mx-2" />,
    label: "Logout",
    className: "text-danger",
    path: ROUTES.AUTH.LOGOUT,
  },
];

export const footerNavLinks = [
  {
    mainHeading: "Lyrics Web",
    subLinks: [
      { label: "Home", path: "/" },
      { label: "About Us", path: "/about-us" },
      { label: "Help", path: "/help" },
      { label: "Contact Us", path: "/contact-us" },
      // { label: "Press", path: "/press" },
    ],
  },
  {
    mainHeading: "Songs",
    subLinks: [
      { label: "Top Songs", path: "/songs" },
      { label: "Releases", path: "/songs" },
      { label: "Add New Song Lyrics", path: "/add-new-song-lyrics" },
      { label: "Playlists", path: "/playlists" },
    ],
  },
  {
    mainHeading: "Artist",
    subLinks: [
      { label: "Top Artists", path: "/artists" },
      { label: "Top Albums", path: "/albums" },
      { label: "Latest News", path: "/news" },
    ],
  },
];

export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;

export const mobileRegex = /^[0-9]{10}$/;
