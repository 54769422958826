import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Spin } from "antd";

import styles from "./MusicMasterQuiz.module.css";
import { StaticImages } from "../../utils/constants";
import { ROUTES } from "../../routes/constants";
import { getAuthConfig } from "../../services/apiUtils";
import { controller } from "../../services/apiServices";
import { formatStringType } from "../../utils/helpers";
import { MdArrowForward } from "react-icons/md";
import ReactConfetti from "react-confetti";

function MusicMasterQuiz({ genreType = "all" }) {
  const navigate = useNavigate();

  const [score, setScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const fetchQuestion = async () => {
    setLoading(true);
    try {
      const payload = {
        genre: "all",
      };
      const config = getAuthConfig();
      const response = await controller.quiz.getQuiz(payload, config);
      if (response.success) {
        setCurrentQuestion(response.data);
        setSelectedAnswer(null);
        setIsAnswerChecked(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch question:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setScore(0); // Reset score
    setTotalQuestions(0); // Reset total questions
    fetchQuestion();
  }, []);

  const handleAnswerSelect = (selectedOption) => {
    setSelectedAnswer(selectedOption);
    setIsAnswerChecked(true);

    if (currentQuestion.correctAnswer === selectedOption) {
      setScore((prevScore) => prevScore + 1);
      setFeedback("Correct! Great job 🎉"); // Show positive feedback
      setShowConfetti(true); // Trigger confetti
      setTimeout(() => setShowConfetti(false), 3000); // Stop confetti after 3 seconds
    } else {
      setFeedback(
        `Wrong! The correct answer was ${currentQuestion.correctAnswer}.`
      ); // Show negative feedback
    }

    setTotalQuestions((prevTotal) => prevTotal + 1); // Increment total questions
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedAnswer(null);
    setIsAnswerChecked(false);
    setFeedback(""); // Reset feedback
    fetchQuestion();
  };

  const renderOptions = () => {
    if (!currentQuestion) return null;

    const { correctAnswer } = currentQuestion;

    return ["A", "B", "C", "D"].map((option, index) => {
      const optionKey = `option${option}`;
      const isCorrect = option === correctAnswer;
      const isSelected = option === selectedAnswer;
      const showCorrectGreen = isAnswerChecked && isCorrect;
      const showIncorrectRed = isAnswerChecked && isSelected && !isCorrect;

      return (
        <motion.div
          key={index}
          whileHover={{
            backgroundColor: "#E6E6FA",
            scale: 1.02,
            transition: { duration: 0.3 },
            cursor: "pointer",
            color: "black",
          }}
          whileTap={{ scale: 0.98 }}
          className="d-flex align-items-center mb-3 rounded-4"
          style={{
            backgroundColor: showCorrectGreen
              ? "#32FF78"
              : showIncorrectRed
              ? "#FF4081"
              : "transparent",
            border: `2px solid ${
              isSelected || isCorrect ? "#007BFF" : "cornflowerblue"
            }`,
            padding: "10px 15px",
            width: "100%",
            color:
              isSelected || showCorrectGreen || showIncorrectRed
                ? "black"
                : "white", // Updated font color
          }}
        >
          <div
            className="rounded-3 text-uppercase me-3 d-flex align-items-center justify-content-center bg-opulentBlue text-white"
            style={{
              width: "40px",
              height: "40px",
              border: `2px solid cornflowerblue`,
            }}
          >
            {formatStringType(option, "lowerCase")}
          </div>
          <Button
            variant="none"
            className="w-100 text-start"
            onClick={() => handleAnswerSelect(option)}
            disabled={isAnswerChecked}
            style={{
              background: "transparent",
              border: "none",
              padding: "0",
              color: "inherit",
              fontSize: "16px",
            }}
          >
            {currentQuestion[optionKey]}
          </Button>
        </motion.div>
      );
    });
  };

  return (
    <Container fluid className="m-0 p-0">
      <div
        className={`position-relative bg-sliceHeaven`}
        style={{
          backgroundImage: `url(${StaticImages.Utils.MusicMasterQuizBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "50% auto",
          backgroundPosition: "left center",
        }}
      >
        <div className={``}>
          <Row className="m-0 p-0">
            <Col xs={12} className="px-5 px-lg-5 py-5 py-lg-5">
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start">
                <h2 className="fw-bold text-white mb-3 mb-sm-0">
                  Music Master Quiz
                </h2>
                <Button
                  variant="hotPink"
                  className="text-white rounded-pill px-4"
                  onClick={() => navigate(ROUTES.MAIN.QUIZ)}
                >
                  Advance Quiz
                </Button>
              </div>
            </Col>

            <div className="px-5 px-lg-5">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="fw-bold text-white mb-0">Score: {score}</p>
                <p className="fw-bold text-white mb-0">
                  Total Questions: {totalQuestions}
                </p>
              </div>
              {/* Feedback message */}
              {feedback && (
                <h4 className="mb-4 text-center">
                  <p className="text-white fw-bold">{feedback}</p>
                </h4>
              )}
            </div>

            {loading ? (
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spin tip="Loading..." size="large" />
              </Col>
            ) : currentQuestion ? (
              <>
                {showConfetti && (
                  <ReactConfetti
                    width={window.innerWidth} // Optional: Use `useWindowSize` for responsive size
                    height={window.innerHeight}
                    numberOfPieces={200} // Number of confetti pieces
                    gravity={0.2} // Adjust gravity for effect
                  />
                )}{" "}
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="d-flex flex-column justify-content-center px-5 px-lg-5"
                >
                  <p className="fw-bold text-white mb-3">
                    Question {currentQuestionIndex + 1}
                  </p>
                  <h3 className="text-white mb-3">
                    {currentQuestion.question}
                  </h3>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="px-5 px-lg-5 py-5 py-lg-5"
                >
                  <Row className="g-3">{renderOptions()}</Row>
                </Col>
              </>
            ) : (
              <>
                <Col xs={12} className="px-5 px-lg-5 py-5 py-lg-5">
                  <p className="text-white text-center fw-bold">
                    No questions available.
                  </p>
                </Col>
              </>
            )}
            <div className="d-flex justify-content-end px-5 px-lg-5 py-5 py-lg-5">
              <Button
                variant="light"
                className={`text-sliceHeaven fw-bold rounded-4 px-4 py-2`}
                onClick={handleNextQuestion}
                disabled={!currentQuestion || loading}
              >
                Next Question <MdArrowForward className="ms-2 fw-bold" />
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default MusicMasterQuiz;
