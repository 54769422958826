import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

// Reusable components
const SectionHeader = ({ text }) => (
  <div className="fw-bold fs-4 my-3">{text}</div>
);

const SectionContent = ({ children }) => <div className="mb-3">{children}</div>;

const BulletList = ({ items }) => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);

const OrderedList = ({ items }) => (
  <ol>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ol>
);

// Privacy Policy Page
function PrivacyPolicyPage() {
  const privacyContent = {
    title: "Privacy Policy",
    lastUpdated: "21 January 2025 (Version 2.0)",
    sections: [
      {
        heading: "Introduction",
        content: (
          <>
            GlobalMedia Holdings Ltd. values your privacy and is committed to
            protecting your personal information. This Privacy Policy outlines
            the types of information we collect, how it is used and protected,
            and how you can manage your information. By using{" "}
            <a href="https://www.lyricsweb.com">www.lyricsweb.com</a> (the
            “Website”), you agree to the practices described in this Privacy
            Policy.
          </>
        ),
      },
      {
        heading: "Your Consent",
        content: (
          <>
            By providing GlobalMedia Holdings Ltd. with Personal Information (as
            defined below), you consent to the terms of this Privacy Policy. If
            you do not agree with any term in this Policy, please do not provide
            your Personal Information or use areas of the Website that require
            it.
            <br />
            If you choose not to provide Personal Information, certain features
            of the Website may not be available to you, such as posting
            comments, accessing restricted areas, or contacting us through
            specific forms.
          </>
        ),
      },
      {
        heading: "Changes to This Privacy Policy",
        content:
          "GlobalMedia Holdings Ltd. reserves the right to revise this Privacy Policy from time to time. Any updates will be posted on this page with the updated effective date. By continuing to use the Website after such updates, you reaffirm your agreement to the revised Privacy Policy.",
      },
      {
        heading: "Information We Collect",
        content: (
          <>
            <SectionHeader text="Personal Information:" />
            <BulletList
              items={[
                "Name and photo.",
                "Internet Protocol (“IP”) address.",
                "Email address, username, and password.",
                "Any other information you provide voluntarily (e.g., in forms or email correspondence).",
              ]}
            />
            <SectionHeader text="Cookies and Tracking Technologies:" />
            <p>
              The Website uses cookies and similar technologies to enhance your
              experience. Cookies are small files stored on your device that
              help us recognize your browser and provide customized features.
              Cookies may also help us analyze Website usage and report
              aggregated data to advertisers or partners.
            </p>
            <p>
              You can control or disable cookies through your browser settings.
              However, disabling cookies may limit your ability to use certain
              features of the Website. For more information, visit{" "}
              <a href="https://www.allaboutcookies.org">
                www.allaboutcookies.org
              </a>{" "}
              or{" "}
              <a href="https://www.aboutcookies.org.uk">
                www.aboutcookies.org.uk
              </a>
              .
            </p>
          </>
        ),
      },
      {
        heading: "Information from Minors",
        content:
          "The Website is not intended for individuals under the age of 13. GlobalMedia Holdings Ltd. does not knowingly collect personal information from children under 13. If we become aware that a child’s information has been collected, we will delete it promptly.",
      },
      {
        heading: "How We Use Your Information",
        content: (
          <>
            GlobalMedia Holdings Ltd. uses Personal Information to:
            <BulletList
              items={[
                "Enhance and personalize your online experience.",
                "Operate, maintain, and improve the Website’s features and functionality.",
                "Respond to user inquiries and provide customer support.",
                "Send service-related updates, promotional offers, or other communications.",
                "Serve tailored advertisements and analyze user behavior.",
              ]}
            />
            <p>
              Aggregated Information: We may use aggregated, non-identifiable
              data to analyze website usage and share such insights with
              advertisers, partners, and others.
            </p>
          </>
        ),
      },
      {
        heading: "Third-Party Service Providers",
        content: (
          <>
            We may share Personal Information with trusted third-party service
            providers who perform functions on our behalf, such as:
            <BulletList
              items={["Payment processing", "Data analysis", "Email delivery"]}
            />
            <p>
              These providers are obligated to use your information solely for
              the purpose of providing these services.
            </p>
          </>
        ),
      },
      {
        heading: "Behavioral Advertising",
        content: (
          <>
            We allow third-party advertising companies to collect information
            about your browsing activity through cookies and other technologies
            to provide targeted advertisements. For more information or to opt
            out, visit{" "}
            <a href="https://www.aboutads.info/choices">
              Digital Advertising Alliance
            </a>
            .
          </>
        ),
      },
      {
        heading: "YouTube API Usage",
        content: (
          <>
            The Website uses YouTube’s API to display videos on certain pages.
            By using the Website, you agree to be bound by Google’s Privacy
            Policy, which can be reviewed at{" "}
            <a href="https://www.google.com/policies/privacy">
              www.google.com/policies/privacy
            </a>
            .
          </>
        ),
      },
      {
        heading: "Links to Third-Party Websites",
        content:
          "The Website may contain links to external websites. GlobalMedia Holdings Ltd. is not responsible for the privacy practices of these third-party websites. We encourage you to review their privacy policies before submitting any information.",
      },
      {
        heading: "Managing Your Personal Information",
        content: (
          <>
            You may review or update your Personal Information by contacting us
            at <a href="mailto:support@lyricsweb.com">support@lyricsweb.com</a>.{" "}
            <br />
            <br />
            If you wish to delete your Personal Information, you may request its
            permanent removal by contacting us. Upon confirmation of your
            request, we will delete your information and ensure it is no longer
            used.
          </>
        ),
      },
      {
        heading: "Security of Your Information",
        content:
          "GlobalMedia Holdings Ltd. takes reasonable precautions to protect your Personal Information from unauthorized access, alteration, or misuse. Despite these measures, no method of transmission over the Internet is 100% secure.",
      },
      {
        heading: "Your Rights",
        content: (
          <>
            Depending on your jurisdiction, you may have the following rights
            concerning your Personal Information:
            <BulletList
              items={[
                "The right to access the Personal Information we hold about you.",
                "The right to request corrections or updates to your Personal Information.",
                "The right to request the deletion of your Personal Information.",
                "The right to withdraw consent for certain data processing activities.",
              ]}
            />
            To exercise these rights, please contact us at{" "}
            <a href="mailto:support@lyricsweb.com">support@lyricsweb.com</a>.
          </>
        ),
      },
      {
        heading: "International Data Transfers",
        content:
          "Your Personal Information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from those of your country. We take appropriate measures to ensure that your information remains protected in accordance with this Privacy Policy.",
      },
      {
        heading: "Contact Us",
        content: (
          <>
            If you have questions or concerns about this Privacy Policy or how
            your data is handled, please contact us at:
            <br />
            Email:{" "}
            <a href="mailto:support@lyricsweb.com">support@lyricsweb.com</a>
          </>
        ),
      },
    ],
  };

  const seoData = getSEOConfig("privacyPolicyPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.privacy_policy_1}
          />
        </div>

        <Row className="m-4 m-lg-2 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <h1 className="fw-bold my-4">{privacyContent.title}</h1>
            <p className="fw-bold">
              Last Updated: {privacyContent.lastUpdated}
            </p>
            <div className="privacy-content">
              {privacyContent.sections.map((section, index) => (
                <div key={index}>
                  <SectionHeader text={section.heading} />
                  <SectionContent>{section.content}</SectionContent>
                </div>
              ))}
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}></Col>
        </Row>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.privacy_policy_2}
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default PrivacyPolicyPage;
