import React, { useEffect } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Select } from "antd";

import { GoogleAdSense } from "../../../utils/constants";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { getConfig } from "../../../services/apiUtils";
import {
  configController,
  setCountryCodes,
} from "../../../features/config/configSlice";
import { validationSchemas } from "../../../utils/validationSchemas";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { controller } from "../../../services/apiServices";

const { Option } = Select;

function ContactUsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countryCodes, isLoading } = useSelector((state) => state.config);

  // Fetch country codes on mount if not available
  useEffect(() => {
    if (!countryCodes || countryCodes.length === 0) {
      const fetchCountryCodes = async () => {
        try {
          const response = await controller.config.fetchCountryCodesAPI();
          dispatch(setCountryCodes(response.data));
        } catch (error) {
          console.error("Failed to fetch country codes:", error);
        }
      };

      fetchCountryCodes();
    }
  }, [dispatch, countryCodes]);

  // Setup react-hook-form with validation schema
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchemas.contactUsSchema),
    mode: "onChange",
    defaultValues: formFieldTypes.contactUsFields.reduce((acc, field) => {
      if (field.name === "countryCode" && countryCodes?.length > 0) {
        acc[field.name] = countryCodes[0]?.cca2; // Default to first country code if available
      } else {
        acc[field.name] = "";
      }
      return acc;
    }, {}),
  });

  const onSubmit = async (payload) => {
    try {
      await dispatch(
        configController.submitContactUs({
          payload: payload,
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error submitting contact form:", error);
    }
  };

  const seoData = getSEOConfig("contactPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div className="m-4 m-lg-2">
          <Row className="m-0 p-0">
            <Col lg={8} md={8} sm={12} xs={12} className="mb-4">
              <div>
                <h1 className="fw-bold my-1">Contact Us</h1>
                <div className="my-3">
                  Our friendly team would love to hear from you.
                </div>

                <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
                  <Row>
                    {formFieldTypes.contactUsFields.map((field) => (
                      <Col
                        key={field.name}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="mb-3"
                      >
                        <Controller
                          name={field.name}
                          control={control}
                          render={({ field: controllerField }) => {
                            if (field.name === "countryCode") {
                              // Ensure countries are unique by using a Set to filter duplicates
                              const uniqueCountryCodes = Array.from(
                                new Set(countryCodes?.map((code) => code?.cca2)) // Use cca2 (alpha-2 country code) for uniqueness
                              ).map((cca2) => {
                                const code = countryCodes.find(
                                  (c) => c?.cca2 === cca2
                                );
                                return {
                                  value: code?.cca2,
                                  label: `${
                                    code?.name?.common ?? "Unknown Country"
                                  } (${code?.idd?.root ?? ""}${
                                    code?.idd?.suffixes?.[0] ?? ""
                                  })`,
                                };
                              });

                              return countryCodes && countryCodes.length > 0 ? (
                                <Select
                                  style={{ width: "100%" }}
                                  size="large"
                                  {...controllerField}
                                  loading={isLoading}
                                  options={uniqueCountryCodes} // Set unique country options
                                  placeholder="Select a country"
                                  onChange={(value) =>
                                    setValue("countryCode", value)
                                  }
                                  value={controllerField.value || undefined} // Ensure correct value is set
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  key={uniqueCountryCodes.length} // Force re-render when countryCodes change
                                />
                              ) : (
                                <Select
                                  placeholder="Loading countries..."
                                  loading
                                />
                              );
                            }

                            // Render other form fields normally (e.g., text, email, etc.)
                            return (
                              <div className="mb-3">
                                <label
                                  htmlFor={field.name}
                                  className="form-label"
                                >
                                  {field.label}
                                </label>
                                <input
                                  {...controllerField}
                                  className={`form-control ${
                                    errors[field.name] ? "is-invalid" : ""
                                  }`}
                                  type={field.type || "text"}
                                  placeholder={field.placeholder}
                                />
                                {errors[field.name] && (
                                  <div className="invalid-feedback">
                                    {errors[field.name]?.message}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        />
                      </Col>
                    ))}
                  </Row>

                  <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <Button
                        type="default"
                        className="mt-3 bg-corbeau text-ghostWhite fw-bold w-100"
                        htmlType="submit"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-center">
              <GoogleAd
                client={GoogleAdSense.client}
                slot={GoogleAdSense.slot.contact_us_1_v}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default ContactUsPage;
