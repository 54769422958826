import React, { useState } from "react";
import { Button, Form, Modal, Upload } from "antd";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UploadOutlined } from "@ant-design/icons";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import RenderField from "../_fields/RenderField";
import { formFieldTypes } from "../../utils/formFieldTypes";
import { validationSchemas } from "../../utils/validationSchemas";
import { playlistController } from "../../features/playlist/playlistSlice";
import { getAuthConfig } from "../../services/apiUtils";
import { uploadFile } from "../../utils/fileUtils";

const AddUserPlaylistModal = ({ show, onClose, onSubmit, fetchPlayList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadedImagePath, setUploadedImagePath] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetUploadImagePath = () => {
    setUploadedImagePath(null);
    setFileList([]);
  };
  // React Hook Form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchemas.addUserPlaylistSchema),
    defaultValues: {
      playlist_name: "",
      playlist_type: "",
      playlist_image: null,
    },
  });

  const handleFormSubmit = async (data) => {
    try {
      const finalData = {
        ...data,
        playlist_image: uploadedImagePath || data.playlist_image,
      };

      await dispatch(
        playlistController.addUserPlaylist({
          payload: finalData,
          config: getAuthConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
      onSubmit(finalData);
      onClose();
      resetUploadImagePath();
      fetchPlayList();
    } catch (error) {
      console.error("Failed to fetch user my playlists:", error);
    }
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    try {
      const filePath = await uploadFile(
        file,
        `/user/upload-playlist-picture`,
        "image"
      );
      setUploadedImagePath(filePath);
    } catch (error) {
      console.error("File upload failed:", error);
    } finally {
      setIsLoading(false);
    }
    return false;
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Modal
      centered
      open={show}
      onCancel={onClose}
      title="Add New Playlist"
      footer={null}
    >
      <Form layout="vertical" onFinish={handleSubmit(handleFormSubmit)}>
        <Row className="g-4">
          {formFieldTypes.addUserPlaylistFields.map((field) => (
            <RenderField
              key={field.name}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
          <Form.Item
            label="Upload Playlist Image (Optional)"
            extra="Accepted formats: JPG, PNG"
          >
            <Upload
              beforeUpload={handleFileUpload}
              accept="image/png, image/jpeg"
              maxCount={1}
              listType="picture"
              fileList={fileList}
              onChange={handleFileChange}
            >
              {/* <Button icon={<UploadOutlined />}>Upload Image</Button> */}
              <Button icon={<UploadOutlined />} disabled={isLoading}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Upload Image"
                )}
              </Button>
            </Upload>
            {/* {uploadedImagePath && <p>Uploaded Path: {uploadedImagePath}</p>} */}
          </Form.Item>
        </Row>
        <div className="mt-4">
          <Button
            variant="solid"
            type="default"
            color="default"
            htmlType="submit"
          >
            Create Playlist
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUserPlaylistModal;
