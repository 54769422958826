import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

import { playlistController } from "../../features/playlist/playlistSlice";
import { getConfig } from "../../services/apiUtils";
import PlaylistCard from "../_cards/PlaylistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";

function ExplorePlaylists() {
  const dispatch = useDispatch();

  const { adminPlaylists, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          playlistController.getAllAdminPlaylists({
            payload: {},
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch trending playlists:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch]);

  return (
    <div className="my-1 my-lg-4">
      <div className="my-1 my-lg-4">
        <h1 className="fw-bold">Explore Playlists</h1>
      </div>

      <Row className="g-3 g-lg-4 m-0 p-0">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "25vh", width: "100%" }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        ) : error ? (
          <>
            <ErrorAlert description={error} />
          </>
        ) : adminPlaylists?.length > 0 ? (
          <Row className="g-3 g-lg-4 bg-ghostWhite m-0 p-0">
            {adminPlaylists?.map((playlist, index) => {
              return (
                <Col key={playlist._id || index} lg={3} md={4} sm={12} xs={12}>
                  <PlaylistCard
                    playlist={playlist}
                    index={index}
                    type="card"
                    user="all"
                  />
                </Col>
              );
            })}
          </Row>
        ) : (
          <p className="text-center">No playlists available at the moment.</p>
        )}
      </Row>
    </div>
  );
}

export default ExplorePlaylists;
