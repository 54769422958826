import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { MdArrowOutward } from "react-icons/md";

import { StaticImages } from "../../utils/constants";
import PodcastCard from "../_cards/PodcastCard";
import { ROUTES } from "../../routes/constants";

const TrendingPodcasts = ({ genreType = "all" }) => {
  const navigate = useNavigate();

  // Array of podcast data
  const podcasts = [
    {
      id: 1,
      name: "Exploring the Music World",
      date: "October 15, 2024",
      image: StaticImages.Podcasts.Img1,
    },
    {
      id: 2,
      name: "Inside the Mind of a Musician",
      date: "October 12, 2024",
      image: StaticImages.Podcasts.Img2,
    },
    {
      id: 3,
      name: "The Evolution of Music Genres",
      date: "October 10, 2024",
      image: StaticImages.Podcasts.Img3,
    },
    {
      id: 4,
      name: "Breaking into the Music Industry",
      date: "October 9, 2024",
      image: StaticImages.Podcasts.Img4,
    },
  ];

  return (
    <div className="bg-foundationWhite p-4">
      <div className="d-flex justify-content-between align-items-center my-3">
        <h1 className="fw-bold mb-0">
          Trending {genreType !== "all" ? _.capitalize(genreType) : ""} Podcasts
        </h1>
        <div className="d-flex align-items-center d-none d-lg-block d-md-block">
          <span className="fw-bold fs-6 me-2">See All</span>
          <Button
            variant="eigengrau"
            size="sm"
            className="fw-bold fs-6 rounded-circle"
            onClick={() => navigate(ROUTES.MAIN.ALBUMS)}
          >
            <MdArrowOutward />
          </Button>
        </div>
      </div>
      {/* <div className={`d-flex overflow-x-scroll ${styles.horizontalScroll}`}>
        {podcasts.map((podcast, index) => (
          <PodcastCard podcast={podcast} index={index} />
        ))}
      </div> */}
      <Row className="m-0 p-0 g-4">
        {podcasts.map((podcast, index) => (
          <Col lg={3} md={4} sm={12} xs={12} key={index}>
            <PodcastCard podcast={podcast} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TrendingPodcasts;
