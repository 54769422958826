import React from "react";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { Col, Container, Row } from "react-bootstrap";
import { GoogleAdSense } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function TermsOfUsePage() {
  const seoData = getSEOConfig("termsPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.terms_of_use_1}
          />
        </div>

        <Row className="m-4 m-lg-2 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <div>
              <h1 className="fw-bold my-4">Terms of Use</h1>
              <p className="fw-bold">
                Last Updated: 21 January 2025 (Version 2.0)
              </p>
              <div className="terms-content">
                <div class="content">
                  <h2>1. GENERAL</h2>
                  <p>
                    <span>1.1.</span>&nbsp;&nbsp;
                    <span>
                      Welcome to{" "}
                      <a
                        href="https://www.LyricsWeb.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.LyricsWeb.com
                      </a>{" "}
                      ("Website"). These Terms and Conditions ("Terms and
                      Conditions") are a legally binding agreement between you,
                      the person or entity that will be accessing or using our
                      website and/or services (referenced below as "You" or
                      "Your"), and GlobalMedia Holdings Ltd. (the "Company" or
                      "We" or “Us”), with respect to the use of the Website.
                    </span>
                  </p>
                  <p>
                    <span>1.2.</span>&nbsp;&nbsp;
                    <span>
                      The Website is a vast online database of song lyrics and
                      album information, catering to music enthusiasts with a
                      thriving community. The lyrics displayed on the Website
                      are sourced from licenses that We hold.
                    </span>
                  </p>
                  <p>
                    <span>1.3.</span>&nbsp;&nbsp;
                    <span>
                      By using the Website, You accept these Terms and
                      Conditions. The Company reserves the right, in its sole
                      discretion, to modify these Terms and Conditions at any
                      time by posting the modified provisions on the Website,
                      and You shall be liable for reviewing and becoming
                      familiar with any such modifications. Please note that any
                      such modifications shall become effective immediately upon
                      posting.{" "}
                      <strong>
                        IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND
                        CONDITIONS, YOU SHOULD NOT USE THE WEBSITE.
                      </strong>
                    </span>
                  </p>
                </div>
                <div class="content">
                  <h2>2. THE SERVICE</h2>
                  <p>
                    <span>2.1.</span>&nbsp;&nbsp;
                    <span>
                      The Website is used solely as a platform for information
                      exchange and communication between users, offering,
                      locating, and accessing song lyrics, album information,
                      and artist details. The Company is not an owner or
                      operator of any content presented by third-party
                      contributors, and its responsibility is limited to
                      facilitating the availability of this content through the
                      Website.
                    </span>
                  </p>
                  <p>
                    <span>2.2.</span>&nbsp;&nbsp;
                    <span>
                      Content contributors declare and undertake that they
                      possess all the necessary legal rights to share the
                      lyrics, album information, and artist details on the
                      Website, and that they are in full compliance with any
                      applicable laws. They also declare that their
                      representations on the Website, including descriptions,
                      images, or other information, do not infringe any right of
                      any third-party.
                    </span>
                  </p>
                  <p>
                    <span>2.3.</span>&nbsp;&nbsp;
                    <span>
                      Users acknowledge that the Company has devoted substantial
                      time and resources in developing its Website and services,
                      and that such information is inherently valuable
                      information owned solely by the Company. Users and any
                      other party shall not circumvent or bypass the Company’s
                      ownership interest in such information, directly or
                      indirectly, except in collaboration with or with the prior
                      express written consent of the Company.
                    </span>
                  </p>

                  <h2>3. USAGE RULES</h2>
                  <p>
                    <span>3.1.</span>&nbsp;&nbsp;
                    <span>
                      The use of the Website, including registration and log-in
                      to Your account, is permitted solely in accordance with
                      these Terms and Conditions.
                    </span>
                  </p>
                  <p>
                    <span>3.2.</span>&nbsp;&nbsp;
                    <span>
                      It is hereby clarified that the Company reserves the right
                      not to register a User or cancel their registration, and
                      in doing so to delete any information uploaded on their
                      behalf to the Website, for any reason or for no reason at
                      all, at its sole discretion, without providing any notice.
                    </span>
                  </p>
                  <p>
                    <span>3.3.</span>&nbsp;&nbsp;
                    <span>
                      Registration, log-in to Your account, reviewing and/or
                      searching and/or providing any information in relation to
                      song lyrics, albums, or artists is permitted solely
                      subject to the following conditions:
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span>3.3.1.</span>&nbsp;&nbsp;
                      <span>
                        The User is an adult (over the age of 18) and legally
                        qualified to perform legally binding actions. If the
                        User is a minor or is not entitled to perform legal
                        actions without the approval of an authorized person on
                        their behalf, their use of the Website shall be deemed
                        to have received such approval.
                      </span>
                    </li>
                    <li>
                      <span>3.3.2.</span>&nbsp;&nbsp;
                      <span>The User has an active e-mail address.</span>
                    </li>
                  </ul>
                  <p>
                    <span>3.4.</span>&nbsp;&nbsp;
                    <span>
                      When You use our Website, You represent, warrant, and
                      covenant that the information You provide Us is accurate,
                      complete, and current at all times. You agree to accept
                      responsibility for any and all activities or actions in
                      relation to Your use of our Website.
                    </span>
                  </p>
                  <p>
                    <span>3.5.</span>&nbsp;&nbsp;
                    <span>
                      Your use of our Website is at Your own discretion and at
                      Your sole risk, and You will be solely liable for any loss
                      of data, damage, or harm to any of Your devices used to
                      use or access the Website. The information obtained by
                      using the Website is provided without warranties of any
                      kind, whether express or implied, including, but not
                      limited to, implied warranties of merchantability, fitness
                      for a particular purpose, title, non-infringement of any
                      right, rule, regulation, or law, or any warranty
                      whatsoever.
                    </span>
                  </p>
                  <p>
                    <span>3.6.</span>&nbsp;&nbsp;
                    <span>
                      The Company, its subsidiaries, and its affiliates do not
                      warrant that: a) the Website will function uninterrupted,
                      secure, or available at any particular time or location;
                      b) any errors or defects will be corrected; or c) the
                      results of using the Website will meet Your requirements.
                      In addition, data or content uploaded to the Website may
                      be lost, damaged, or otherwise unrecoverable.
                    </span>
                  </p>

                  <h2>4. PROHIBITED USE</h2>
                  <p>
                    <span>4.1.</span>&nbsp;&nbsp;
                    <span>
                      You hereby undertake that You will not, and will not
                      permit or authorize third parties to, use the Website in
                      any way that: (i) is defamatory, abusive, threatening,
                      racist, or constitutes an invasion of a right of privacy
                      of another person, or is otherwise offensive, violent,
                      vulgar, obscene, or otherwise harms or harasses or can
                      reasonably be expected to harm or harass any person or
                      entity; (ii) is illegal or encourages or advocates illegal
                      activity; (iii) posts or transmits any communication or
                      solicitation designed or intended to obtain private
                      information from any third party; (iv) contains viruses,
                      trojan horses, worms, or any other computer programs
                      designed to interrupt, destroy, or limit the functionality
                      of the Website or any system, computer software, hardware,
                      or telecommunications equipment; (v) uses a false identity
                      or impersonates another person; (vi) violates or infringes
                      any rights (including, without limitation, privacy rights,
                      copyright, or other intellectual property rights) of any
                      third party; (vii) acts in a manner that is damaging to
                      the Company’s reputation and/or goodwill; (viii) violates
                      these Terms and Conditions and/or our Privacy Policy or
                      any applicable local, state, national, or international
                      law or regulation; or (ix) otherwise attempts to interfere
                      with the proper working of the Website or the services
                      provided.
                    </span>
                  </p>
                  <p>
                    <span>4.2.</span>&nbsp;&nbsp;
                    <span>
                      4.2. In addition, except as expressly authorized herein,
                      You will not, and will not permit or authorize third
                      parties to: (i) take any action intended to circumvent or
                      disable the operation of any security feature or measure
                      of the Website; (ii) publish, distribute, sell, disclose,
                      market, sublicense, rent, lease, display, provide,
                      transfer, or make available any Company’s Content and/or
                      the Website or any portion thereof, to any third party;
                      (iii) reverse engineer, disassemble, enhance, or otherwise
                      modify or use the source code of the Website or any part
                      thereof; (iv) modify, reproduce, or create derivative
                      works from the Website or any part thereof; (v) access or
                      use the Website via automated means, including by
                      crawling, scraping, caching, Bots or otherwise (except as
                      may be the result of standard search engine protocols or
                      technologies used by a search engine with our express
                      consent).
                    </span>
                  </p>
                </div>
                <div class="content">
                  <h2>5. USAGE OF LYRICS</h2>
                  <p>
                    <span>5.1.</span>&nbsp;&nbsp;
                    <span>
                      Usage of lyrics is limited to your personal, noncommercial
                      use in accordance with the terms of this Terms and
                      Conditions. You may not reproduce (other than as
                      authorized for your own personal usage), publish,
                      transmit, distribute, publicly display, rent or lend,
                      modify, create derivative works from, sell or participate
                      in the sale of or exploit in any way, in whole or in part,
                      directly or indirectly, any of the lyrics so provided. You
                      agree that you are not granted any so-called "karaoke" or
                      "sing-along" rights to lyrics and you shall not seek to or
                      remove any vocal track from a sound recording that shall
                      be associated with a lyric provided to you. You agree not
                      to assign, transfer or transmit any lyrics to any third
                      party. You agree that you shall not seek to or do anything
                      that will defeat, evade or circumvent any efforts that may
                      be made to protect the lyrics from any unauthorized usage.
                    </span>
                  </p>

                  <h2>6. REGISTRATION AND USE OF INFORMATION</h2>
                  <p>
                    <span>6.1.</span>&nbsp;&nbsp;
                    <span>
                      The registration and use of a personal account on Our
                      Website and/or entering contact information for receiving
                      information and/or contacting Us and the use of
                      information provided by You shall be in accordance with
                      our Privacy Policy.
                    </span>
                  </p>
                  <p>
                    <span>6.2.</span>&nbsp;&nbsp;
                    <span>
                      You must provide accurate and correct information to use
                      the Website quickly and without discrepancies. Typing
                      false information of any kind is strictly prohibited.
                    </span>
                  </p>
                  <p>
                    <span>6.3.</span>&nbsp;&nbsp;
                    <span>
                      You are solely responsible for Your activity on Our
                      Website. You must immediately notify Us of any breach of
                      security and/or unauthorized use of the Website.
                    </span>
                  </p>

                  <h2>7. INTELLECTUAL PROPERTY; COMPANY’S & USER’S CONTENT</h2>
                  <p>
                    <span>7.1.</span>&nbsp;&nbsp;
                    <span>
                      The manner of displaying information on the Website shall
                      be determined from time to time at the full and exclusive
                      discretion of the Company.
                    </span>
                  </p>
                  <p>
                    <span>7.2.</span>&nbsp;&nbsp;
                    <span>
                      The Company retains all right, title, and interest in and
                      to the Website (including all related intellectual
                      property rights). Subject to these Terms and Conditions,
                      the Company hereby grants You a limited, personal,
                      non-transferable, non-exclusive, non-assignable permission
                      to use the Website.
                    </span>
                  </p>
                  <p>
                    <span>7.3.</span>&nbsp;&nbsp;
                    <span>
                      All photos, texts, scripts, designs, graphics, logos,
                      audios, videos, songs, interactive features, interfaces,
                      software, code, trademarks, service marks, trade names,
                      and other content used, displayed, included, incorporated,
                      uploaded, posted, or published by the Company as part of
                      the Website (other than Your content) are the sole
                      property of the Company and/or their licensors ("Company's
                      Content"), and subject to copyright and other intellectual
                      property rights under applicable laws. You may not use,
                      download, distribute and/or copy them, in whole or in
                      part, without the prior written permission of the Company.
                    </span>
                  </p>
                  <p>
                    <span>7.4.</span>&nbsp;&nbsp;
                    <span>
                      The Company may allow Users to post comments, questions,
                      reviews, feedback, etc., and to contact the Company and/or
                      other Users in relation to the Website and/or its content.
                      The Company is not liable for any kind of feedback,
                      comment, review, or question that originates from Users of
                      the Website and reserves the right to remove them without
                      notice, at its sole discretion.
                    </span>
                  </p>
                  <p>
                    <span>7.5.</span>&nbsp;&nbsp;
                    <span>
                      If You believe in good faith that any material or content
                      made available on or through the Website has been used or
                      exploited in a manner that infringes and/or violates any
                      of Your rights, including intellectual property rights,
                      please send Us prompt written notice via e-mail (at:{" "}
                      <a href="mailto:support@lyricsweb.com">
                        support@lyricsweb.com
                      </a>{" "}
                      ), specifying the exact location of the infringing
                      material, relevant details of the nature of the
                      infringement, etc. We will examine Your notice within a
                      reasonable time, and if We find that the use of the
                      material/content in question does indeed violate Your
                      rights, then We will remove the relevant material/content
                      from the Website.
                    </span>
                  </p>
                  <p>
                    <span>7.6.</span>&nbsp;&nbsp;
                    <span>
                      Our Website allows You to contact Us by providing contact
                      information and a description of Your application/request
                      (“Content”). You are responsible for the Content that You
                      provide Us on the Website, including its legality,
                      reliability, and appropriateness. By providing Us the
                      Content, You represent and warrant that: (i) the Content
                      is yours and/or You have the right to use it and the right
                      to grant Us the rights to use it as described in these
                      Terms and Conditions and/or our Privacy Policy; and (ii)
                      the Content does not violate any privacy rights, publicity
                      rights, copyrights, trademarks, contract rights, or any
                      other rights of any person or entity. You retain any and
                      all of Your rights to any Content You submit on our
                      Website. We take no responsibility and assume no liability
                      for Content You or any third-party provide Us on or
                      through the Website. However, by providing Us the Content
                      on our Website, You hereby grant Us the right and
                      permission to use, modify, and publicly display that
                      Content to assist You with Your application/request. The
                      Company has the right, but not the obligation, to monitor,
                      edit, or delete all Content provided by You at any time.
                    </span>
                  </p>
                </div>
                <div class="content">
                  <h2>8. GENERAL TERMS OF USE</h2>
                  <p>
                    <span>8.1.</span>&nbsp;&nbsp;
                    <span>
                      The Company reserves the right to change, suspend, take
                      offline or discontinue Your account, its Website, at its
                      sole discretion, at any time and without notice or
                      liability.
                    </span>
                  </p>
                  <p>
                    <span>8.2.</span>&nbsp;&nbsp;
                    <span>
                      You are solely responsible for the activity that occurs in
                      relation to the Website by You or on Your behalf. You must
                      notify the Company immediately of any breach of security
                      or unauthorized use of the Company's Website. The Company
                      will not be liable for any losses caused by any use of the
                      Company Website.
                    </span>
                  </p>
                  <p>
                    <span>8.3.</span>&nbsp;&nbsp;
                    <span>
                      You are responsible for obtaining and maintaining any
                      equipment, hardware, software, or ancillary services
                      required to access and use the Website, and for any fees,
                      taxes, penalties, deposits, and/or other costs charged by
                      any third parties in connection therewith.
                    </span>
                  </p>
                  <p>
                    <span>8.4.</span>&nbsp;&nbsp;
                    <span>
                      If You send the Company any feedback or suggestions
                      regarding the Website, You acknowledge that the Company
                      may use them at its sole discretion, without any
                      obligation to compensate You in any manner for such
                      feedback or suggestions.
                    </span>
                  </p>
                  <p>
                    <span>8.5.</span>&nbsp;&nbsp;
                    <span>
                      It is hereby clarified that the Website may contain links
                      to other third parties' Websites, applications, or
                      features. If You choose to visit any such third party’s
                      Websites, applications, or features, please be aware that
                      such third party’s own terms of use and privacy policy
                      will apply and govern Your activities. The Company does
                      not make any representation or warranty regarding such
                      third party's Websites, applications, features, services,
                      products, or content and is not liable in any manner for
                      Your interaction with such third parties.
                    </span>
                  </p>
                  <p>
                    <span>8.6.</span>&nbsp;&nbsp;
                    <span>
                      Third party services, if offered through the Website, are
                      not controlled by the Company and it does not endorse any
                      such third party services, and in no event shall the
                      Company be liable for any third party services or third
                      party service providers.
                    </span>
                  </p>

                  <h2>9. TERMINATION</h2>
                  <p>
                    <span>
                      The Company may terminate or suspend Your account or the
                      Website (or any part thereof) immediately, without prior
                      notice or liability, at its sole discretion. All
                      provisions of these Terms and Conditions that by their
                      nature should survive termination shall remain in full
                      force and effect following termination. Termination of
                      these Terms and Conditions shall not relieve You from any
                      obligation that arose or accrued prior to such
                      termination.
                    </span>
                  </p>

                  <h2>10. WARRANTY AND DISCLAIMER</h2>
                  <p>
                    <span>
                      YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE
                      WEBSITE IS AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS
                      TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT
                      IS SOLELY WITH YOU. THE WEBSITE IS PUBLISHED WITHOUT
                      WARRANTY OF ANY KIND. THE COMPANY DOES NOT WARRANT THAT
                      YOUR USE OF THE WEBSITE WILL BE UNINTERRUPTED, ERROR-FREE
                      OR WILL MEET YOUR SPECIFIC REQUIREMENTS. THE COMPANY MAKES
                      NO WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED,
                      REGARDING THE WEBSITE, THE CONTENT AND YOUR USE THEREOF,
                      INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
                      FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, AVAILABILITY,
                      SECURITY, COMPATIBILITY, NON-INFRINGEMENT OR COMPLETENESS
                      OF RESPONSES, RESULTS AND LACK OF NEGLIGENCE.
                    </span>
                  </p>

                  <h2>11. LIMITATION OF LIABILITY</h2>
                  <p>
                    <span>11.1.</span>&nbsp;&nbsp;
                    <span>
                      THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE, OR
                      HARM, INCLUDING PERSONAL INJURY, OR ANY SPECIAL,
                      INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES,
                      INCLUDING LOSS OF DATA, LOSS OF GOODWILL, LOSS OF PROFITS,
                      OR OTHER PECUNIARY LOSS, IN CONNECTION WITH OR ARISING OUT
                      OF THESE TERMS AND CONDITIONS, THE WEBSITE, ANY
                      TRANSACTION, OR YOUR USE OF OR INABILITY TO USE THE
                      WEBSITE, HOWEVER CAUSED, ON ANY THEORY OF LIABILITY.
                    </span>
                  </p>
                  <p>
                    <span>11.2.</span>&nbsp;&nbsp;
                    <span>
                      IN NO EVENT SHALL THE COMPANY, ITS SHAREHOLDERS,
                      DIRECTORS, OFFICERS OR EMPLOYEES, BE LIABLE FOR ANY LOSS,
                      DAMAGE, OR HARM, INCLUDING PERSONAL INJURY, OR ANY
                      SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR INDIRECT
                      DAMAGES, INCLUDING LABOR COSTS, LOSS OF DATA, LOSS OF
                      GOODWILL, LOSS OF PROFITS, LOSS OF SAVINGS, LOSS OF
                      BUSINESS INFORMATION, OR LOSS OF USE OR OTHER PECUNIARY
                      LOSS, IN CONNECTION WITH OR ARISING OUT OF THESE TERMS AND
                      CONDITIONS, THE WEBSITE, OR YOUR USE OF OR INABILITY TO
                      USE THE WEBSITE, HOWEVER CAUSED, ON ANY THEORY OF
                      LIABILITY, REGARDLESS OF WHETHER THE COMPANY HAS BEEN
                      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT
                      DEROGATING FROM THE ABOVE, IN NO CASE SHALL THE AGGREGATE
                      LIABILITY OF THE COMPANY AND ITS SHAREHOLDERS, DIRECTORS,
                      OFFICERS AND EMPLOYEES UNDER THESE TERMS AND CONDITIONS OR
                      ARISING OUT OF A TRANSACTION OR OTHERWISE RELATED TO YOUR
                      USE OF THE WEBSITE EXCEED THE TOTAL FEES ACTUALLY PAID TO
                      THE COMPANY (IF PAYABLE).
                    </span>
                  </p>
                </div>
                <div class="content">
                  <h2>12. INDEMNIFICATION</h2>
                  <p>
                    <span>
                      Upon its first demand, You will indemnify and hold the
                      Company, its shareholders, directors, officers and
                      employees, harmless from any claim (including third-party
                      claim), liability, cost, loss, damage and expense
                      (including reasonable legal fees) caused due to (a) Your
                      access and use of the Website in violation of these Terms
                      and Conditions or in violation or infringement of any
                      rights (including, without limitation, privacy right,
                      copyright, or other intellectual property rights) of any
                      third party and applicable law, and (b) Your breach of any
                      applicable law or regulation, of these Terms and
                      Conditions, including any representation or undertaking
                      hereunder.
                    </span>
                  </p>

                  <h2>13. DMCA NOTICE – COPYRIGHT INFRINGEMENT POLICY</h2>
                  <p>
                    <span>13.1.</span>&nbsp;&nbsp;
                    <span>
                      If you believe that your copyrighted work has been copied
                      in a way that constitutes copyright infringement and is
                      accessible via the website{" "}
                      <a
                        href="http://www.lyricsweb.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.lyricsweb.com
                      </a>{" "}
                      (the "Service"), please notify LyricsWeb's copyright
                      agent, as set forth in the Digital Millennium Copyright
                      Act of 1998 ("DMCA"). For your complaint to be valid under
                      the DMCA, you must provide the following information in
                      writing:
                    </span>
                  </p>
                  <p>
                    <span>13.1.1.</span>&nbsp;&nbsp;
                    <span>
                      An electronic or physical signature of a person authorized
                      to act on behalf of the copyright owner;
                    </span>
                  </p>
                  <p>
                    <span>13.1.2.</span>&nbsp;&nbsp;
                    <span>
                      Identification of the copyrighted work that you claim has
                      been infringed;
                    </span>
                  </p>
                  <p>
                    <span>13.1.3.</span>&nbsp;&nbsp;
                    <span>
                      Identification of the material that is claimed to be
                      infringing and where it is located on the Service;
                    </span>
                  </p>
                  <p>
                    <span>13.1.4.</span>&nbsp;&nbsp;
                    <span>
                      Information reasonably sufficient to permit LyricsWeb to
                      contact you, such as your address, telephone number, and
                      email address;
                    </span>
                  </p>
                  <p>
                    <span>13.1.5.</span>&nbsp;&nbsp;
                    <span>
                      A statement that you have a good faith belief that use of
                      the material in the manner complained of is not authorized
                      by the copyright owner, its agent, or the law;
                    </span>
                  </p>
                  <p>
                    <span>13.1.6.</span>&nbsp;&nbsp;
                    <span>
                      A statement, made under penalty of perjury, that the above
                      information is accurate, and that you are the copyright
                      owner or are authorized to act on behalf of the copyright
                      owner.
                    </span>
                  </p>
                  <p>
                    <span>13.2.</span>&nbsp;&nbsp;
                    <span>
                      The above information must be submitted via the following
                      email address:{" "}
                      <a href="mailto:support@lyricsweb.com">
                        support@lyricsweb.com
                      </a>
                      .
                    </span>
                  </p>
                  <p>
                    <span>13.3.</span>&nbsp;&nbsp;
                    <span>
                      UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT
                      ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO
                      CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES,
                      INCLUDING MONETARY DAMAGES, COURT COSTS, AND ATTORNEYS’
                      FEES.
                    </span>
                  </p>
                  <p>
                    <span>13.4.</span>&nbsp;&nbsp;
                    <span>
                      Please note that this procedure is exclusively for
                      notifying LyricsWeb and its affiliates that your
                      copyrighted material has been infringed. The preceding
                      requirements are intended to comply with LyricsWeb's
                      rights and obligations under the DMCA, including 17 U.S.C.
                      §512(c), but do not constitute legal advice. You may wish
                      to consult an attorney regarding your rights and
                      obligations under the DMCA and other applicable laws.
                    </span>
                  </p>
                  <p>
                    <span>13.5.</span>&nbsp;&nbsp;
                    <span>
                      In accordance with the DMCA and other applicable law,
                      LyricsWeb has adopted a policy of terminating, in
                      appropriate circumstances and at LyricsWeb's sole
                      discretion, users who are deemed to be repeat infringers.
                      LyricsWeb may also, at its sole discretion, limit access
                      to the Service and/or terminate the accounts of any users
                      who infringe the intellectual property rights of others,
                      whether or not there is any repeat infringement.
                    </span>
                  </p>

                  <h2>14. MISCELLANEOUS</h2>
                  <p>
                    <span>14.1.</span>&nbsp;&nbsp;
                    <span>
                      These Terms and Conditions shall be governed exclusively
                      by the laws of the State of Israel, without regard to
                      conflict of laws provisions. The parties hereto submit the
                      exclusive jurisdiction to the competent courts of
                      Tel-Aviv-Jaffa.
                    </span>
                  </p>
                  <p>
                    <span>14.2.</span>&nbsp;&nbsp;
                    <span>
                      These Terms and Conditions and our Privacy Policy
                      constitute the entire agreement between You and the
                      Company with respect to the access and/or use of the
                      Website, and supersede all prior or contemporaneous
                      understandings regarding such subject matter. The Privacy
                      Policy is available at:{" "}
                      <a href="/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </span>
                  </p>
                  <p>
                    <span>14.3.</span>&nbsp;&nbsp;
                    <span>
                      The Company reserves the right to update these Terms and
                      Conditions from time to time, with or without notice, and
                      will post its updated Terms and Conditions on its Website.
                      Your continued use of our Website will be subject to the
                      then-current Terms and Conditions. If any modification is
                      unacceptable to You, You may cease using the Website. If
                      You do not cease using the Website, You will be deemed to
                      have accepted those modifications.
                    </span>
                  </p>
                  <p>
                    <span>14.4.</span>&nbsp;&nbsp;
                    <span>
                      The Company may assign at any time any of its rights
                      and/or obligations hereunder to any third party without
                      Your consent. You and Users may not assign any of the
                      rights granted to you and/or your liabilities, without the
                      Company's prior written consent. Any attempt to do so
                      without the Company's prior consent will be void.
                    </span>
                  </p>
                  <p>
                    <span>14.5.</span>&nbsp;&nbsp;
                    <span>
                      These Terms and Conditions will also govern any future
                      upgrades or updates or new releases provided by the
                      Company in connection with the Website, unless any such
                      upgrades or updates are accompanied by a separate set of
                      terms and conditions, in which case the terms of that
                      upgraded or updated terms and conditions will govern.
                    </span>
                  </p>
                  <p>
                    <span>14.6.</span>&nbsp;&nbsp;
                    <span>
                      In the event that a court of competent jurisdiction finds
                      any provision of these Terms and Conditions to be illegal,
                      invalid or unenforceable, the remaining provisions will
                      remain in full force and effect.
                    </span>
                  </p>
                  <p>
                    <span>14.7.</span>&nbsp;&nbsp;
                    <span>
                      The failure of the Company to enforce any right or
                      provision in these Terms and Conditions will not
                      constitute a waiver of such right or provision unless
                      agreed by the Company in writing.
                    </span>
                  </p>
                  <p>
                    <span>14.8.</span>&nbsp;&nbsp;
                    <span>
                      If You have any questions or queries about these Terms and
                      Conditions or our services in general, please do not
                      hesitate to contact us via e-mail at:{" "}
                      <a href="mailto:support@lyricsweb.com">
                        support@lyricsweb.com
                      </a>
                      .
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}></Col>
        </Row>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.terms_of_use_2}
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default TermsOfUsePage;
