import { createSlice } from "@reduxjs/toolkit";
import { commentThunks } from "./commentThunks";

const initialState = {
  lyricsComments: [],
  quizController: [],
  isLoading: false,
  error: null,
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const handlePending = (state, key) => {
      state[key] = true;
      state.error = null;
    };

    const handleRejected = (state, action, key) => {
      state[key] = false;
      state.error = action.payload;
    };

    const handleFulfilled = (state, action, updateState) => {
      updateState(state, action.payload);
    };

    builder
      // Get User Comments List
      .addCase(commentThunks.getUserCommentsLyrics.pending, (state) =>
        handlePending(state, "isFetchingComment")
      )
      .addCase(commentThunks.getUserCommentsLyrics.fulfilled, (state, action) =>
        handleFulfilled(state, action, (state, payload) => {
          const { comments, page, totalComments, totalPages } = payload;
          state.isFetchingComment = false;
          if (page === 1) {
            state.commentList = comments;
          } else {
            state.commentList = [...state.commentList, ...comments];
          }
          state.currentPage = page;
          state.totalPages = totalPages;
          state.totalComments = totalComments;
        })
      )
      .addCase(commentThunks.getUserCommentsLyrics.rejected, (state, action) =>
        handleRejected(state, action, "isFetchingComment")
      )

      // Add User Comment
      .addCase(commentThunks.addUserCommentLyrics.pending, (state) =>
        handlePending(state, "isModifyingComment")
      )
      .addCase(commentThunks.addUserCommentLyrics.fulfilled, (state, action) =>
        handleFulfilled(state, action, (state, payload) => {
          state.isModifyingComment = false;
          state.commentList = [payload.data, ...state.commentList];
          state.totalComments += 1;
        })
      )
      .addCase(commentThunks.addUserCommentLyrics.rejected, (state, action) =>
        handleRejected(state, action, "isModifyingComment")
      )

      // Delete User Comment
      .addCase(commentThunks.deleteUserCommentsLyrics.pending, (state) =>
        handlePending(state, "isModifyingComment")
      )
      .addCase(
        commentThunks.deleteUserCommentsLyrics.fulfilled,
        (state, action) =>
          handleFulfilled(state, action, (state, payload) => {
            state.isModifyingComment = false;
            state.commentList = state.commentList.filter(
              (comment) => comment._id !== payload.commentId
            );
            state.totalComments -= 1;
          })
      )
      .addCase(
        commentThunks.deleteUserCommentsLyrics.rejected,
        (state, action) => handleRejected(state, action, "isModifyingComment")
      );
  },
});

export const commentController = commentThunks;

export default commentSlice.reducer;
