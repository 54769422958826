import React from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import _ from "lodash";

import { StaticImages } from "../../utils/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";
import { BsMusicNoteList } from "react-icons/bs";
import { formatStringType } from "../../utils/helpers";

// Reusable Overlay Component
const OverlayWrapper = ({ text, children }) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
    {children}
  </OverlayTrigger>
);

function SongCard({ song, index, type = "default" }) {
  const navigate = useNavigate();

  const renderSongDetails = (maxWidth = "100%") => (
    <div className="d-flex align-items-center my-2">
      {type === "grid" || type === "dark" ? (
        <>
          <Image
            src={song?.song_image_link || StaticImages.App.AppDefaultS}
            alt={`${song?.song_name} avatar`}
            roundedCircle
            height={40}
            width={40}
            loading="lazy"
          />
        </>
      ) : type === "myLyricsList" ? null : (
        <>
          <Image
            src={song?.song_image_link || StaticImages.App.AppDefaultS}
            alt={`${song?.song_name} avatar`}
            rounded
            height={40}
            width={40}
            loading="lazy"
          />
        </>
      )}

      <div className="ms-2">
        <OverlayWrapper text={song?.song_name}>
          <div
            className="text-truncate fw-bold"
            style={{ maxWidth, cursor: "pointer" }}
            onClick={() =>
              navigate(
                generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                  songName: _.kebabCase(song?.song_name),
                  isrcKey: song.isrc_code,
                })
              )
            }
          >
            {song?.song_name}
          </div>
        </OverlayWrapper>
        <OverlayWrapper text={song?.artist?.artist_name}>
          <div
            className="text-truncate"
            style={{ maxWidth, cursor: "pointer" }}
            onClick={() =>
              navigate(
                generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                  artistName: _.kebabCase(song?.artist?.artist_name),
                  artistId: song?.artist?.artist_id,
                })
              )
            }
          >
            {song?.artist?.artist_name}
          </div>
        </OverlayWrapper>
      </div>
    </div>
  );

  const renderHoverButton = () => (
    <motion.div
      className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end p-2"
      initial={{ opacity: 0 }}
      whileHover={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Button
        variant="light"
        className="fw-bold rounded-3"
        size="sm"
        onClick={() =>
          navigate(
            generateDynamicRoute(ROUTES.MAIN.VIEW_SONG_LYRICS, {
              songName: formatStringType(song.song_name, "kebabCase"),
              isrcKey: song.isrc_code,
            })
          )
        }
      >
        <BsMusicNoteList className="me-2" />
        View Song
      </Button>
    </motion.div>
  );

  const renderCardContent = () => (
    <motion.div
      className="position-relative"
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.02 }}
    >
      {/* Card Image */}
      <Image
        src={song?.song_image_link || StaticImages.App.AppDefaultS}
        alt={song.song_name}
        height={200}
        width={200}
        className="rounded-4"
        style={{ objectFit: "cover" }}
        loading="lazy"
      />
      {renderHoverButton()}
    </motion.div>
  );

  if (type === "grid") {
    return (
      <Card className="rounded-4 p-3 bg-white border-0 overflow-hidden">
        {renderCardContent()}
        <div>{renderSongDetails()}</div>
      </Card>
    );
  }

  if (type === "dark") {
    return (
      <Card className="rounded-4 p-3 bg-corbeau text-white border-0 overflow-hidden">
        {renderCardContent()}
        <div>{renderSongDetails()}</div>
      </Card>
    );
  }

  if (type === "myLyricsList") {
    return (
      <div key={index} className="d-flex align-items-center mb-2 rounded-4">
        <div className="fw-bold me-3">{_.padStart(index + 1, 2, "0")}</div>
        <Image
          src={song?.song_image_link || StaticImages.App.AppDefaultS}
          alt={`${song?.song_name || "Unknown Song"} avatar`}
          className="rounded me-3"
          style={{ width: "50px", height: "50px" }}
          loading="lazy"
        />
        <div>{renderSongDetails("150px")}</div>
      </div>
    );
  }

  // Default type
  return (
    <div key={index} className="d-flex align-items-center mb-2 rounded-4">
      <div className="fw-bold me-3">{_.padStart(index + 1, 2, "0")}</div>
      {/* <Image
        src={song?.song_image_link || StaticImages.App.AppDefaultS}
        alt={`${song?.song_name || "Unknown Song"} avatar`}
        className="rounded me-3"
        style={{ width: "50px", height: "50px" }}
      /> */}
      <div>{renderSongDetails("150px")}</div>
    </div>
  );
}

export default SongCard;
