import { createSlice } from "@reduxjs/toolkit";
import { configThunks } from "./configThunks";

const initialState = {
  genres: [],
  featuredVideos: [],
  countryCodes: [],
  isLoading: false,
  error: null,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setCountryCodes: (state, action) => {
      state.countryCodes = action.payload;
    },
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.isLoading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    };

    builder
      // getGenres handlers
      .addCase(configThunks.getGenres.pending, handlePending)
      .addCase(configThunks.getGenres.fulfilled, (state, action) => {
        state.isLoading = false;
        state.genres = action.payload.data || []; // Safely assigning data to state.genres
      })
      .addCase(configThunks.getGenres.rejected, handleRejected)

      // getFeaturedVideos handlers
      .addCase(configThunks.getFeaturedVideos.pending, handlePending)
      .addCase(configThunks.getFeaturedVideos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.featuredVideos = action.payload.data || [];
      })
      .addCase(configThunks.getFeaturedVideos.rejected, handleRejected)

      // submitContactUs handlers
      .addCase(configThunks.submitContactUs.pending, handlePending)
      .addCase(configThunks.submitContactUs.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(configThunks.submitContactUs.rejected, handleRejected);
  },
});

export const { setCountryCodes } = configSlice.actions;

export const configController = configThunks;

export default configSlice.reducer;
