import React from "react";
import { Container } from "react-bootstrap";

import styles from "./ViewSongLyricsPage.module.css";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import ViewLyricsSection from "../../../components/_sections/ViewLyricsSection";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function ViewSongLyricsPage() {
  return (
    <MainLayout className={styles.printStyles_body}>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.lyrics_1}
          />
        </div>

        <div
          id="noPrint"
          className={`m-4 m-lg-2 ${styles.printStyles_noPrint}`}
        >
          <ViewLyricsSection />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ViewSongLyricsPage;
