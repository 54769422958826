import React, { useEffect, useState } from "react";
import { Modal, Input, List, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { playlistController } from "../../features/playlist/playlistSlice";
import { getAuthConfig } from "../../services/apiUtils";
import { controller } from "../../services/apiServices";
import { showToast } from "../_toasts/notificationUtils";
import _ from "lodash";

const AddSongToUserPlaylist = ({
  visible,
  onClose,
  onAddToPlaylist,
  lyrics,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const { userMyPlaylists, isLoading } = useSelector((state) => state.playlist);

  // Fetch user playlists with error handling
  const fetchUserPlaylists = async () => {
    try {
      await dispatch(
        playlistController.getUserMyPlaylists({
          payload: {},
          config: getAuthConfig(),
        })
      ).unwrap();
    } catch (error) {
      console.error("Failed to fetch user playlists:", error);
      showToast("error", "Failed to load playlists", 2);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchUserPlaylists();
      setSearchTerm(""); // Clear search when the modal opens
    }
  }, [visible]);

  // Safe check for adding a song to a playlist
  const handleAddToPlaylist = async (playlistId, lyrics) => {
    try {
      // Ensure lyrics and songData exist
      if (!lyrics || !lyrics.songData || !lyrics.songData.song_id) {
        console.error("Invalid song data");
        showToast("error", "Invalid song data", 2);
        return;
      }

      const songId = lyrics.songData.song_id.toString();

      const response = await controller.playlist.addSongUserPlayList(
        {
          playlist_id: playlistId,
          song_id: songId,
        },
        getAuthConfig()
      );

      if (response.status === 201) {
        showToast("success", response.message, 2);
        onAddToPlaylist(playlistId, songId); // Update parent component
        fetchUserPlaylists(); // Refresh playlists after adding song
        onClose(); // Close modal after success
      }
    } catch (error) {
      console.error("Error adding song to playlist:", error);
      showToast("error", "Failed to add song to playlist", 2);
    }
  };

  // Safe filtering of playlists
  const filteredPlaylists = Array.isArray(userMyPlaylists)
    ? userMyPlaylists.filter((playlist) =>
        playlist.playlist_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <Modal
      centered
      title="Add to Playlist"
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Input
        placeholder="Search Playlists"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />

      <div>
        {/* Playlist Name and Action headings */}
        <div className="d-flex justify-content-between mb-3">
          <h6 className="fw-bold">Playlist Name</h6>
          <h6 className="fw-bold">Action</h6>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={filteredPlaylists}
          loading={isLoading}
          renderItem={(playlist, index) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() =>
                    playlist._id && lyrics
                      ? handleAddToPlaylist(playlist._id, lyrics)
                      : null
                  }
                >
                  Add to Playlist
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <div>
                    <span className="fw-bold">
                      {_.padStart(index + 1, 2, "0")}
                    </span>
                    {playlist?.playlist_name || "Unnamed Playlist"}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default AddSongToUserPlaylist;
