import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import _ from "lodash";

import { getAuthConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { lyricsController } from "../../features/lyrics/lyricsSlice";
import SongCard from "../_cards/SongCard";

function MyLyrics({ display = 4, mode = "dark" }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { myLyricsSongs, isLoading, error } = useSelector(
    (state) => state.lyrics || {}
  );

  useEffect(() => {
    dispatch(
      lyricsController.getMyLyricsSongs({
        payload: {},
        config: getAuthConfig(),
      })
    ).catch((error) => console.error("Failed to fetch my lyrics:", error));
  }, [dispatch]);

  return (
    <div className="bg-white rounded-4 p-4">
      <div className="my-4">
        <div className="d-flex justify-content-between align-items-center my-3">
          <h1 className="fw-bold mb-0">My Lyrics</h1>
          <div className="d-flex align-items-center d-none d-lg-block d-md-block">
            <span className="fw-bold fs-6 me-2">See All</span>
            <Button
              variant="eigengrau"
              size="sm"
              className="fw-bold fs-6 rounded-circle"
              // onClick={() => navigate(ROUTES.MAIN.ALBUMS)}
            >
              <MdArrowOutward />
            </Button>
          </div>
        </div>

        <Row className="g-4 m-0 p-0">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "25vh", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : myLyricsSongs?.length > 0 ? (
            <Row className="g-4 m-0 p-0">
              {myLyricsSongs?.map((song, index) => (
                <>
                  <Col lg={6} md={6} sm={12} xs={12} key={index}>
                    <SongCard type="myLyricsList" song={song} index={index} />
                  </Col>
                </>
              ))}
            </Row>
          ) : (
            <p className="text-center">No my lyrics available at the moment.</p>
          )}
        </Row>
      </div>
    </div>
  );
}

export default MyLyrics;
