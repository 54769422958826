import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Spin } from "antd";

import { ErrorAlert } from "../_alerts/UIAlerts";

function GenresSection({ background = "white", color = "eigengrau" }) {
  const {
    genres = [],
    isLoading,
    error,
  } = useSelector((state) => state.config);
  return (
    <div className="my-4">
      <Row className={`bg-${background} p-5 rounded-0 rounded-lg-3 m-0`}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className={`fw-bold text-${color}`}>Genres</h1>
        </div>

        {isLoading ? (
          <Col className="d-flex justify-content-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "25vh", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>{" "}
          </Col>
        ) : error ? (
          <>
            <ErrorAlert description={error} />
          </>
        ) : genres?.length > 0 ? (
          genres?.map((genre, index) => (
            <Col key={index} lg={3} md={3} sm={6} xs={6} className="mb-3">
              <Button
                as={motion.button}
                variant="link"
                className={`w-100 text-start fw-bold py-2 fs-6 text-decoration-none text-${color}`}
                size="lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              >
                {genre.genreName || "Unknown"}
              </Button>
            </Col>
          ))
        ) : (
          <Col className="text-center">No genres available.</Col>
        )}
      </Row>
    </div>
  );
}

export default GenresSection;
