import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Upload,
  Col,
  Row,
} from "antd";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { controller } from "../../../services/apiServices";
import { getAuthConfig } from "../../../services/apiUtils";
import { showToast } from "../../../components/_toasts/notificationUtils";

const validationSchema = yup.object({
  songName: yup.string().required("Song Name is required"),
  lyrics: yup.string().required("Lyrics are required"),
  language: yup.string().required("Language is required"),
  releaseYear: yup.date().required("Release Year is required"),
  genres: yup
    .array()
    .min(1, "Please select at least one genre")
    .required("Genres are required"),
  styles: yup
    .array()
    .min(1, "Please select at least one style")
    .required("Styles are required"),
  album_name: yup.string().required("Album name is required"),
  performedBy: yup.string().required("Performer is required"),
  gender: yup.string().required("Gender is required"),
  artistBio: yup.string().required("Artist bio is required"),
  youtubeURL: yup
    .string()
    .matches(
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
      "Please enter a valid YouTube link"
    )
    .required("Please enter a valid YouTube link"),

  artistWebsite: yup
    .string()
    .matches(
      /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/,
      "Please enter a valid website URL"
    )
    .required("Please enter a valid website URL"),
  writtenBy: yup.string().required("Writer's name is required"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
});

function AddLyricsSection() {
  const [fileList, setFileList] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { artistPicture: [] },
  });

  const onSubmit = async (payload) => {
    const { artistPicture, ...modifiedPayload } = payload;
    try {
      const response = await controller.lyrics.contributeLyricsUser(
        modifiedPayload,
        getAuthConfig()
      );
      if (response.success) {
        showToast("success", response.message, 2);
        reset();
      }
    } catch (error) {
      console.error("Error submitting form contribute lyrics:", error);
    }
  };

  const handleFileChange = (info) => {
    const { fileList } = info;
    setFileList(fileList);
    setValue("artistPicture", fileList);
  };

  const renderFormItem = (label, name, renderField, span = 24) => (
    <Col span={span}>
      <Form.Item
        label={label}
        validateStatus={errors[name] ? "error" : ""}
        help={errors[name]?.message}
      >
        <Controller name={name} control={control} render={renderField} />
      </Form.Item>
    </Col>
  );

  const selectOptions = (options) =>
    options.map((opt) => ({ value: opt.toLowerCase(), label: opt }));

  return (
    <div>
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          {renderFormItem("Song Name", "songName", ({ field }) => (
            <Input {...field} placeholder="Enter the song name" size="large" />
          ))}

          {renderFormItem("Lyrics", "lyrics", ({ field }) => (
            <ReactQuill
              {...field}
              theme="snow"
              placeholder="Enter the lyrics"
            />
          ))}

          {renderFormItem(
            "Language",
            "language",
            ({ field }) => (
              <Select
                {...field}
                placeholder="Select a language"
                size="large"
                options={selectOptions([
                  "English",
                  "Spanish",
                  "French",
                  "Hindi",
                ])}
              />
            ),
            12
          )}

          {renderFormItem(
            "Release Year",
            "releaseYear",
            ({ field }) => (
              <DatePicker
                {...field}
                placeholder="Select release year"
                size="large"
              />
            ),
            12
          )}

          {renderFormItem("Album Name", "album_name", ({ field }) => (
            <Input {...field} placeholder="Enter the album name" size="large" />
          ))}

          {renderFormItem(
            "Styles",
            "styles",
            ({ field }) => (
              <Select
                {...field}
                mode="multiple"
                placeholder="Select styles"
                size="large"
                options={selectOptions(["Jazz", "Folk", "Blues", "Country"])}
              />
            ),
            12
          )}

          {renderFormItem(
            "Genres",
            "genres",
            ({ field }) => (
              <Select
                {...field}
                mode="multiple"
                placeholder="Select genres"
                size="large"
                options={selectOptions(["Pop", "Rock", "Hip-Hop", "Classical"])}
              />
            ),
            12
          )}

          {renderFormItem("Performed By", "performedBy", ({ field }) => (
            <Input
              {...field}
              placeholder="Enter the performer's name"
              size="large"
            />
          ))}

          {renderFormItem("Artist Picture", "artistPicture", ({ field }) => (
            <Upload
              {...field}
              listType="picture"
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              onRemove={() => setFileList([])}
            >
              <Button>Upload Artist Picture</Button>
            </Upload>
          ))}

          {renderFormItem("Gender", "gender", ({ field }) => (
            <Radio.Group {...field}>
              <Radio value="Male">Male</Radio>
              <Radio value="Female">Female</Radio>
              <Radio value="Group">Group</Radio>
            </Radio.Group>
          ))}

          {renderFormItem("Artist Bio", "artistBio", ({ field }) => (
            <ReactQuill
              {...field}
              theme="snow"
              placeholder="Enter a brief artist bio"
            />
          ))}

          {renderFormItem("YouTube URL", "youtubeURL", ({ field }) => (
            <Input {...field} placeholder="Enter YouTube URL" size="large" />
          ))}

          {renderFormItem("Artist Website", "artistWebsite", ({ field }) => (
            <Input
              {...field}
              placeholder="Enter artist website URL"
              size="large"
            />
          ))}

          {renderFormItem("Written By", "writtenBy", ({ field }) => (
            <Input
              {...field}
              placeholder="Enter the writer's name"
              size="large"
            />
          ))}

          {renderFormItem("Email Address", "email", ({ field }) => (
            <Input {...field} placeholder="Enter your email" size="large" />
          ))}

          <Col span={8}>
            <Form.Item>
              <Button
                variant="solid"
                color="default"
                htmlType="submit"
                size="large"
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddLyricsSection;
