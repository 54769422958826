import React, { useEffect } from "react";
import { Col, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import _ from "lodash";

import { GoogleAdSense, StaticImages } from "../../utils/constants";
import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import { stringUtils } from "../../utils/utilities";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import GoogleAd from "../Advertisements/GoogleAd";

function ExploreSongs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { exploreSongs, isLoading, error } = useSelector(
    (state) => state.song || {}
  );

  useEffect(() => {
    const fetchExploreSongs = async () => {
      try {
        await dispatch(
          songController.getExploreSongs({
            payload: {},
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to explore songs:", error);
      }
    };

    fetchExploreSongs();
  }, [dispatch]);

  // Helper function to render a song row
  const renderSongRow = (song, index) => (
    <tr key={index} className="align-middle">
      <td className="py-3">
        {/* Mobile and Small Devices Layout */}
        <div className="d-block d-lg-none">
          <div className="d-flex align-items-center">
            {/* Image */}
            <Image
              fluid
              src={song?.song_image_link || StaticImages.App.AppDefaultS}
              alt={song?.song_name}
              width="50"
              height="50"
              rounded
              className="me-3"
              loading="lazy"
            />

            {/* Song Name and Album */}
            <div>
              <div
                className="fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                      songName: _.kebabCase(song?.song_name),
                      isrcKey: song?.isrc_code,
                    })
                  )
                }
              >
                {song?.song_name || "Unknown Song"}
              </div>
              <small
                className="text-muted"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                      albumName: _.kebabCase(song?.album?.album_name),
                      albumId: song?.album?.album_id,
                    })
                  )
                }
              >
                {stringUtils.sanitizeName(song?.album?.album_name) ||
                  "Unknown Album"}
              </small>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            {/* Artist Name */}
            <small
              className="text-muted"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                    artistName: _.kebabCase(song?.artist?.artist_name),
                    artistId: song?.artist?.artist_id,
                  })
                )
              }
            >
              {song?.artist?.artist_name || "Unknown Artist"}
            </small>

            {/* Views */}
            <small className="text-muted">
              {song?.views?.toLocaleString() || 0}
            </small>
          </div>
        </div>

        {/* Desktop and Large Devices Layout */}
        <div className="d-none d-lg-flex align-items-center">
          {/* Image */}
          <Image
            fluid
            src={song?.song_image_link || StaticImages.App.AppDefaultS}
            alt={song?.song_name}
            width="50"
            height="50"
            rounded
            className="me-3"
            loading="lazy"
          />

          {/* Song Details */}
          <div className="d-flex flex-column flex-lg-row w-100 justify-content-between">
            <div>
              {/* Song Name */}
              <div
                className="fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                      songName: _.kebabCase(song?.song_name),
                      isrcKey: song?.isrc_code,
                    })
                  )
                }
              >
                {song?.song_name || "Unknown Song"}
              </div>

              {/* Album Name */}
              <small
                className="text-muted"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                      albumName: _.kebabCase(song?.album?.album_name),
                      albumId: song?.album?.album_id,
                    })
                  )
                }
              >
                {stringUtils.sanitizeName(song?.album?.album_name) ||
                  "Unknown Album"}
              </small>
            </div>

            <div className="d-flex justify-content-end align-items-center">
              {/* Artist Name */}
              <small
                className="text-muted me-3"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                      artistName: _.kebabCase(song?.artist?.artist_name),
                      artistId: song?.artist?.artist_id,
                    })
                  )
                }
              >
                {song?.artist?.artist_name || "Unknown Artist"}
              </small>

              {/* Views */}
              <small className="text-muted">
                {song?.views?.toLocaleString() || 0}
              </small>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );

  return (
    <div className="my-1 my-lg-4">
      <div className="my-1 my-lg-4">
        <h1 className="fw-bold">Explore Songs</h1>
      </div>

      <Row className="g-3 g-lg-4 m-0 p-0">
        {/* Songs Section */}
        <Col lg={9} md={9} sm={12} xs={12}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "25vh", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <ErrorAlert description={error} />
          ) : exploreSongs?.length > 0 ? (
            <>
              <Row className="g-3 g-lg-4 m-0 p-0 bg-white rounded-4 d-none d-lg-block">
                <Table borderless responsive className="p-2">
                  <thead>
                    <tr>
                      <th className="text-start">Song</th>
                      <th className="text-start">Album</th>
                      <th className="text-start">Artist</th>
                      <th className="text-start text-nowrap">Times Played</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exploreSongs.map((song, index) => (
                      <tr key={index} className="align-middle">
                        <td className="py-3 d-flex align-items-center">
                          <Image
                            // fluid
                            src={
                              song?.song_image_link ||
                              StaticImages.App.AppDefaultS
                            }
                            alt={song?.song_name}
                            width="50"
                            height="50"
                            rounded
                            className="me-3"
                            loading="lazy"
                          />
                          <div>
                            <div
                              className="fw-bold"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                                    songName: _.kebabCase(song?.song_name),
                                    isrcKey: song?.isrc_code,
                                  })
                                )
                              }
                            >
                              {song?.song_name || "Unknown Song"}
                            </div>
                          </div>
                        </td>
                        <td className="py-3">
                          <span
                            className="text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                                  albumName: _.kebabCase(
                                    song?.album?.album_name
                                  ),
                                  albumId: song?.album?.album_id,
                                })
                              )
                            }
                          >
                            {stringUtils.sanitizeName(
                              song?.album?.album_name
                            ) || "Unknown Album"}
                          </span>
                        </td>
                        <td className="py-3">
                          <span
                            className="text-muted"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                generateDynamicRoute(
                                  ROUTES.MAIN.VIEW_ARTIST_BIO,
                                  {
                                    artistName: _.kebabCase(
                                      song?.artist?.artist_name
                                    ),
                                    artistId: song?.artist?.artist_id,
                                  }
                                )
                              )
                            }
                          >
                            {song?.artist?.artist_name || "Unknown Artist"}
                          </span>
                        </td>
                        <td className="py-3">
                          <span className="text-muted">
                            {song?.views?.toLocaleString() || 0}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <div className="d-block d-lg-none bg-ghostWhite">
                {exploreSongs.map((song, index) => (
                  <>
                    <div className="d-flex align-items-center my-1">
                      {/* Image */}
                      <Image
                        // fluid
                        src={
                          song?.song_image_link || StaticImages.App.AppDefaultS
                        }
                        alt={song?.song_name}
                        width="50"
                        height="50"
                        rounded
                        className="me-3"
                        loading="lazy"
                      />

                      {/* Song Name and Album */}
                      <div className="d-flex flex-column">
                        <div
                          className="fw-bold"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                                songName: _.kebabCase(song?.song_name),
                                isrcKey: song?.isrc_code,
                              })
                            )
                          }
                        >
                          {song?.song_name || "Unknown Song"}
                        </div>
                        <small
                          className="text-muted"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                                albumName: _.kebabCase(song?.album?.album_name),
                                albumId: song?.album?.album_id,
                              })
                            )
                          }
                        >
                          {stringUtils.sanitizeName(song?.album?.album_name) ||
                            "Unknown Album"}
                        </small>
                      </div>

                      {/* Artist Name and Views (aligned to the right) */}
                      <div className="d-flex flex-column ms-auto mt-2">
                        {/* Artist Name */}
                        <small
                          className="text-muted"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              generateDynamicRoute(
                                ROUTES.MAIN.VIEW_ARTIST_BIO,
                                {
                                  artistName: _.kebabCase(
                                    song?.artist?.artist_name
                                  ),
                                  artistId: song?.artist?.artist_id,
                                }
                              )
                            )
                          }
                        >
                          {song?.artist?.artist_name || "Unknown Artist"}
                        </small>

                        {/* Views */}
                        <small className="text-muted mt-1">
                          {song?.views?.toLocaleString() || 0}
                        </small>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <p className="text-center">No Songs available at the moment.</p>
          )}
        </Col>

        {/* Ads Section */}
        <Col lg={3} md={3} sm={12} xs={12}>
          <div className="mb-4">
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.songs_2_v}
            />
          </div>
          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.songs_3_s}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ExploreSongs;
