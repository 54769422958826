import React, { useEffect, useState } from "react";
import { DislikeOutlined, LikeOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, List, Space, Typography, Spin } from "antd";
import { useNavigate } from "react-router-dom";

import { getAuthToken } from "../../../utils/authHelpers";
import { ROUTES } from "../../../routes/constants";
import { getAuthConfig } from "../../../services/apiUtils";
import { controller } from "../../../services/apiServices";
import { showToast } from "../../../components/_toasts/notificationUtils";
import { jwtDecode } from "jwt-decode";

function QuizCommentSection({ currentQuestion }) {
  const navigate = useNavigate();
  const [checkToken, setCheckToken] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const getCommentList = async () => {
    try {
      const payload = {
        quiz_id: currentQuestion?._id,
      };

      const config = getAuthConfig();
      const response = await controller.quiz.getUserCommentQuiz(
        payload,
        config
      );
      if (response.success) {
        setCommentList(response.data);
      } else {
        setCommentList([]);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    const token = getAuthToken();
    setCheckToken(token !== null);
  }, []);

  // Submit a new comment
  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!commentValue.trim()) {
      showToast("error", "Comment cannot be empty.", 2);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        quiz_id: currentQuestion?._id,
        comment: commentValue.trim(),
      };

      const config = getAuthConfig();
      const response = await controller.quiz.addUserCommentQuiz(
        payload,
        config
      );
      if (response.success) {
        setCommentValue("");
        showToast("success", response.message, 2);
        getCommentList();
      } else {
        showToast("error", response.message, 2);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
      showToast("error", error.message, 2);
    } finally {
      setLoading(false);
    }
  };

  // Delete a comment
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await controller.quiz.deleteUserCommentQuiz(
        { comment_id: commentId, quiz_id: currentQuestion?._id },
        getAuthConfig()
      );

      if (response.success) {
        showToast("success", response.message, 2);
        setCommentList((prev) =>
          prev.filter((comment) => comment._id !== commentId)
        );
      } else {
        showToast("error", response.message, 2);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
      showToast("error", error.message, 2);
    }
  };

  const handleLikeDislike = async (commentId, isLike) => {
    try {
      const payload = { commentId, isLike }; // `action` can be "like" or "dislike"
      const response = await controller.lyrics.reactLikeDislikeComment(
        payload,
        getAuthConfig()
      );

      if (response.status === 200) {
        getCommentList();
      } else {
        // showToast(
        //   "error",
        //   response.message || "Failed to process reaction.",
        //   2
        // );
      }
    } catch (error) {
      console.error("Error in like/dislike:", error);
      // showToast("error", error.message || "Error processing reaction.", 2);
    }
  };

  useEffect(() => {
    getCommentList();
  }, [currentQuestion]);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      setCheckToken(true);
      const user = jwtDecode(token);
      setUserId(user?._id);
    } else {
      setCheckToken(false);
    }
  }, []);

  return (
    <div>
      <h1 className="d-flex justify-content-between align-items-center">
        <span className="fw-bold">Comments</span>
        {!checkToken && (
          <Button
            variant="solid"
            color="default"
            size="large"
            onClick={() => navigate(ROUTES.AUTH.LOGIN)}
          >
            Sign In
          </Button>
        )}
      </h1>

      {/* Input Field for Comment */}
      <div className="my-3">
        <Input
          placeholder="comment..."
          size="large"
          disabled={!checkToken}
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
          suffix={
            <Button
              color="default"
              variant="text"
              disabled={!checkToken || loading}
              onClick={handleCommentSubmit}
            >
              {loading ? <Spin /> : <SendOutlined />}
            </Button>
          }
        />
      </div>

      {/* List of Comments */}
      <div style={{ scrollbarWidth: "thin", scrollbarColor: "#888 #e0e0e0" }}>
        <List
          bordered={false}
          dataSource={commentList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.user.avatar} size={50} />}
                title={
                  <Space direction="vertical" size={0}>
                    <Typography.Text strong>
                      {item.user.username}
                    </Typography.Text>
                  </Space>
                }
                description={
                  <>
                    <div style={{ marginBottom: "8px" }}>{item.comment}</div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Space size="large">
                        <Space>
                          <LikeOutlined
                            onClick={() => handleLikeDislike(item?._id, true)}
                            style={{ cursor: "pointer", color: "blue" }}
                          />
                          {item?.likes?.length > 0 && item?.likes?.length}
                        </Space>
                        <Space>
                          <DislikeOutlined
                            onClick={() => handleLikeDislike(item._id, false)}
                            style={{ cursor: "pointer", color: "red" }}
                          />
                          {item?.dislikes?.length > 0 && item?.dislikes?.length}
                        </Space>
                        {item?.user?._id === userId && (
                          <Space>
                            <Button
                              danger
                              type="text"
                              size="small"
                              onClick={() => handleDeleteComment(item._id)}
                            >
                              Delete
                            </Button>
                          </Space>
                        )}
                      </Space>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.createdAt}
                      </Typography.Text>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default QuizCommentSection;
