import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Pagination, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { GoogleAdSense } from "../../../utils/constants";
import { albumController } from "../../../features/album/albumSlice";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopAlbums from "../../../components/_sections/TopAlbums";
import ExploreAlbums from "../../../components/_sections/ExploreAlbums";
import TopBar from "../../../components/_buttons/TopBar";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

const AlbumsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { albumDetails } = location.state || {};

  const { albumId, albumName } = useParams();
  const { albumList, isAlbumLoading, error } = useSelector(
    (state) => state.album
  );

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAlbums = async (page) => {
    if (albumId && page > 0) {
      try {
        await dispatch(
          albumController.getAlbumsList({
            payload: { albumId: albumId, page },
            config: {},
          })
        );
      } catch (err) {
        console.error("Error Fetching Albums:", err);
      }
    }
  };

  useEffect(() => {
    fetchAlbums(currentPage);
  }, [albumId, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const seoData = getSEOConfig("albumPage", { albumName, albumId, albumList });

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.albums_1}
          />
        </div>
        <div className="m-4 m-lg-2">
          <TopAlbums />
        </div>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.albums_2}
          />
        </div>
        <div className="m-4 m-lg-2">
          <ExploreAlbums />
        </div>
      </Container>
    </MainLayout>
  );
};

export default AlbumsPage;
