import { message, notification } from "antd";

/**
 * Show a global toast message
 * @param {('info' | 'success' | 'error' | 'warning' | 'loading')} type - Type of the message.
 * @param {string} content - Content of the message.
 * @param {number} [duration=3] - Duration for the message to stay, in seconds. Set to 0 for persistent.
 */
export const showToast = (type, content, duration = 3) => {
  message[type](content, duration);
};

/**
 * Show a global notification
 * @param {('info' | 'success' | 'error' | 'warning')} type - Type of the notification.
 * @param {string} title - Title of the notification.
 * @param {string} description - Description of the notification.
 * @param {number} [duration=4.5] - Duration for the notification to stay, in seconds. Set to 0 for persistent.
 * @param {ReactNode} [icon] - Optional custom icon.
 */

/**
 * Show a global notification
 * Supports both object-based and positional argument calls for flexibility.
 * @param {string | object} typeOrOptions - Type of the notification (or an options object).
 * @param {string} [title] - Title of the notification (if using positional arguments).
 * @param {string} [description] - Description of the notification (if using positional arguments).
 * @param {number} [duration=4.5] - Duration for the notification (if using positional arguments).
 * @param {ReactNode} [icon] - Optional custom icon (if using positional arguments).
 */
export const showNotification = (
  typeOrOptions,
  title,
  description,
  duration = 4.5,
  icon = null
) => {
  // Handle object-based calls
  if (typeof typeOrOptions === "object") {
    const {
      type = "info",
      title = "Notification",
      description = null,
      duration = 4.5,
      icon = null,
    } = typeOrOptions;

    notification[type]({
      message: title,
      description,
      duration,
      icon,
    });
    return;
  }

  // Handle positional arguments
  try {
    const type = typeOrOptions || "info";
    notification[type]({
      message: title || "Notification",
      description: description || "",
      duration,
      icon,
    });
  } catch (error) {
    console.error("Notification Error:", error.message);

    // Fallback for invalid type
    notification.info({
      message: "Notification Error",
      description: "An issue occurred displaying the notification.",
      duration: 4.5,
    });
  }
};
