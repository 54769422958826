import React from "react";
import { List, Typography, Spin, Avatar, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

const { Title, Text } = Typography;

const AlbumSongsSection = ({ albumSongs, isLoading }) => {
  const navigate = useNavigate();

  const tableData = albumSongs?.songs?.map((song) => ({
    ...song,
    song: song,
  }));

  const isEmpty =
    tableData?.length === 0 ||
    (tableData?.length === 1 && Object.keys(tableData[0]).length === 0);

  return (
    <div style={{ padding: "20px" }}>
      <Title level={1}>Songs</Title>

      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin tip="Loading..." size="large" />
        </div>
      ) : isEmpty ? (
        <Text type="secondary">Song List is empty</Text>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={tableData}
          renderItem={(song, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text strong style={{ marginRight: "10px" }}>
                      {_.padStart(index + 1, 2, "0")}
                    </Text>
                    <Avatar
                      shape="square"
                      src={song.song_image_link}
                      size={40}
                    />
                  </div>
                }
                title={
                  <Row>
                    <Col xs={24} sm={16}>
                      <Text
                        strong
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                              songName: song.song_name,
                              isrcKey: song.isrc_code,
                            })
                          )
                        }
                      >
                        {song.song_name || "Song Title"}
                      </Text>
                    </Col>
                    <Col xs={24} sm={8} style={{ textAlign: "right" }}>
                      <Text type="secondary">
                        {song.views.toLocaleString()}
                      </Text>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default AlbumSongsSection;
