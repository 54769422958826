import React, { useEffect, useMemo } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Spin } from "antd";

import { artistController } from "../../features/artist/artistSlice";
import { getAuthConfig } from "../../services/apiUtils";
import ArtistCard from "../_cards/ArtistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";

function FavouriteArtists({ display = 4, genreType = "all", mode = "dark" }) {
  const dispatch = useDispatch();

  const { favouriteArtists, isLoading, error } = useSelector(
    (state) => state.artist || {}
  );

  useEffect(() => {
    dispatch(
      artistController.getFavouriteArtists({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: getAuthConfig(),
      })
    ).catch((error) => console.error("Failed to fetch artists:", error));
  }, [dispatch, genreType]);

  const randomArtists = useMemo(() => {
    return favouriteArtists?.length > 0
      ? [...favouriteArtists].sort(() => Math.random() - 0.5).slice(0, display)
      : [];
  }, [favouriteArtists, display]);

  return (
    <div>
      {mode === "dark" ? (
        <>
          <div className="p-lg-5 bg-corbeau">
            <div>
              <div className="d-flex justify-content-between text-white align-items-center my-3">
                <h1 className="fw-bold mb-0">
                  Favourite {genreType !== "all" ? _.capitalize(genreType) : ""}{" "}
                  Artists
                </h1>
                <div className="d-flex align-items-center d-none d-lg-block d-md-block">
                  <span className="fw-bold fs-6 me-2">See All</span>
                  <Button
                    variant="light"
                    size="sm"
                    className="fw-bold fs-6 rounded-circle"
                    // onClick={() => navigate(ROUTES.MAIN.ALBUMS)}
                  >
                    <MdArrowOutward />
                  </Button>
                </div>
              </div>

              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "25vh", width: "100%" }}
                >
                  <Spin tip="Loading..." size="large" />
                </div>
              ) : error ? (
                <>
                  <ErrorAlert description={error} />
                </>
              ) : randomArtists.length > 0 ? (
                <Row className="g-4 p-lg-5 py-4">
                  {randomArtists.map((artist, index) => (
                    <>
                      <Col key={artist.id || index} lg={3} md={4} sm={6} xs={6}>
                        <ArtistCard
                          viewMode="card"
                          artist={artist}
                          index={index}
                        />
                      </Col>
                    </>
                  ))}
                  <div className="ms-auto text-white">
                    <span className="fw-bold mx-2">See All</span>
                    <Button
                      variant="light"
                      size="sm"
                      className="fw-bold fs-6 ms-auto rounded-circle"
                    >
                      <MdArrowOutward />
                    </Button>
                  </div>
                </Row>
              ) : (
                <p className="text-center text-white">
                  No Favourite artists available at the moment.
                </p>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="my-4">
            <div className="my-4">
              <h1 className="fw-bold">
                Favourite {genreType !== "all" ? _.capitalize(genreType) : ""}{" "}
                Artists
              </h1>
            </div>

            <Row className="g-4 m-0 p-0">
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "25vh", width: "100%" }}
                >
                  <Spin tip="Loading..." size="large" />
                </div>
              ) : error ? (
                <>
                  <ErrorAlert description={error} />
                </>
              ) : randomArtists.length > 0 ? (
                <Row className="g-4 bg-ghostWhite m-0 p-0">
                  {randomArtists.map((artist, index) => (
                    <>
                      <Col lg={3} md={4} sm={6} xs={6} key={index}>
                        <ArtistCard
                          viewMode="badge"
                          artist={artist}
                          index={index}
                        />{" "}
                      </Col>
                    </>
                  ))}

                  <div className="ms-auto d-flex align-items-center float-end">
                    <span className="fw-bold">See All</span>
                    <Button
                      variant="eigengrau"
                      size="sm"
                      className="fw-bold fs-6 mx-2 rounded-circle"
                    >
                      <MdArrowOutward />
                    </Button>
                  </div>
                </Row>
              ) : (
                <p className="text-center">
                  No top explore albums available at the moment.
                </p>
              )}
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default FavouriteArtists;
