import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import TopSongs from "../../../components/_sections/TopSongs";
import NewReleases from "../../../components/_sections/NewReleases";
import ExploreSongs from "../../../components/_sections/ExploreSongs";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function SongsPage() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.songs_1}
          />
        </div>
        <div className="m-4 m-lg-2">
          <TopSongs />
        </div>
        <div className="m-0 m-lg-2">
          <NewReleases />
        </div>
        <div className="m-4 m-lg-2">
          <ExploreSongs />
        </div>
      </Container>
    </MainLayout>
  );
}

export default SongsPage;
