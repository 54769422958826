import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spin } from "antd";
import _ from "lodash";

import MainLayout from "../../../layouts/MainLayout";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import TopBar from "../../../components/_buttons/TopBar";
import { ErrorAlert } from "../../../components/_alerts/UIAlerts";
import { getConfig } from "../../../services/apiUtils";
import { artistController } from "../../../features/artist/artistSlice";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import { ROUTES } from "../../../routes/constants";
import { formatStringType } from "../../../utils/helpers";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function FilterArtistsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { artistLetter } = useParams();

  const { artistsByLetter, isLoading, error } = useSelector(
    (state) => state.artist
  );

  const [localArtists, setLocalArtists] = useState([]); // Local state for artists
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  useEffect(() => {
    // Reset state and fetch data when `artistLetter` changes
    setCurrentPage(1);
    setLocalArtists([]);
    fetchArtists(1);
  }, [artistLetter]);

  const fetchArtists = async (page) => {
    try {
      const { data, status } = await dispatch(
        artistController.getArtistsByLetter({
          payload: { query: artistLetter, page },
          config: getConfig(),
        })
      ).unwrap();

      // Append new artists to local state
      setLocalArtists((prevArtists) => [
        ...prevArtists,
        ...(data?.artistList || []),
      ]);
    } catch (error) {
      console.error("Failed to fetch artists:", error);
    }
  };

  const handleSeeMore = async () => {
    const nextPage = currentPage + 1;
    setIsFetchingNextPage(true);
    try {
      await fetchArtists(nextPage);
      setCurrentPage(nextPage);
    } finally {
      setIsFetchingNextPage(false);
    }
  };

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.filter_artists_1}
          />
        </div>
        <div className="m-4 m-lg-2">
          <div className="pt-2">
            <h1 className="text-start fw-bold">
              Artists - {formatStringType(artistLetter)}
            </h1>
          </div>
          <Row className="g-4 m-0 p-0 bg-ghostWhite">
            <Col lg={8} md={8} sm={12} xs={12} className="bg-ghostWhite">
              {isLoading && currentPage === 1 ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "25vh", width: "100%" }}
                >
                  <Spin tip="Loading..." size="large" />
                </div>
              ) : error ? (
                <ErrorAlert description={error} />
              ) : localArtists && localArtists.length > 0 ? (
                <>
                  <Row className="g-4 m-0 p-0 bg-white rounded-4">
                    <>
                      <Table borderless responsive className="p-2">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Songs</th>
                          </tr>
                        </thead>
                        <tbody>
                          {localArtists.map((artist, index) => (
                            <tr key={index} className="align-middle">
                              <td className="">
                                {_.padStart(index + 1, 2, "0")}
                              </td>
                              <td className="d-flex align-items-center">
                                <Image
                                  fluid
                                  src={
                                    artist?.downloaded_image_link ||
                                    StaticImages.Album.Img1
                                  }
                                  width="40px"
                                  rounded
                                  className="mx-3"
                                  loading="lazy"
                                />
                                <span
                                  className="fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      generateDynamicRoute(
                                        ROUTES.MAIN.VIEW_ARTIST_BIO,
                                        {
                                          artistName: _.kebabCase(
                                            artist?.artist_name
                                          ),
                                          artistId: artist?.artist_id,
                                        }
                                      )
                                    )
                                  }
                                >
                                  {artist?.artist_name}
                                </span>
                              </td>
                              <td>{artist?.total_songs}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  </Row>
                  <div className="d-flex justify-content-center m-2">
                    <Button
                      variant="text"
                      color="default"
                      size="sm"
                      className="fw-bold fs-6 mx-2"
                      onClick={handleSeeMore}
                      disabled={isFetchingNextPage}
                      loading={isFetchingNextPage}
                    >
                      See More
                    </Button>
                  </div>
                </>
              ) : (
                <p className="text-center">
                  No artists available at the moment.
                </p>
              )}
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <GoogleAd
                client={GoogleAdSense.client}
                slot={GoogleAdSense.slot.filter_artists_2_v}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default FilterArtistsPage;
