import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Result } from "antd"; // Ant Design spinner for fallback UI
import { controller } from "../../../services/apiServices";
import { ROUTES } from "../../../routes/constants";
import { getConfig } from "../../../services/apiUtils";
import { setAuthToken, setAuthUser } from "../../../utils/authHelpers";

const GoogleAuthCallback = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get("code");
        const errorParam = urlParams.get("error");

        if (errorParam) {
          setError(errorParam);
          setLoading(false);
          return;
        }

        if (authCode) {
          // Send the auth code to your backend for further processing
          const response = await controller.auth.completeGoogleSignIn(
            {
              code: authCode,
            },
            getConfig()
          );
          if (response.status === 200) {
            setAuthToken(response.data.token);
            setAuthUser(
              response.data.alreadyUser
                ? response.data.alreadyUser
                : response.data.newUser
            );
            navigate(ROUTES.MAIN.HOME); // Redirect to the dashboard
          } else {
            throw new Error("Failed to authenticate with Google.");
          }
        } else {
          throw new Error("No authorization code found in the URL.");
        }
      } catch (err) {
        console.error("Google Auth Callback Error:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spin size="large" tip="Processing your sign-in..." />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Authentication Failed"
        subTitle={error}
        extra={
          <button
            className="btn btn-primary"
            onClick={() => navigate(ROUTES.AUTH.LOGIN)}
          >
            Try Again
          </button>
        }
      />
    );
  }

  return null; // This state should never be reached
};

export default GoogleAuthCallback;
