import React from "react";

import SEOManager from "../../../utils/SEOManager";
import MainLayout from "../../../layouts/MainLayout";
import ChangePasswordSection from "../../../components/_sections/ChangePasswordSection";
import EditProfileSection from "../../../components/_sections/EditProfileSection";
import TopBar from "../../../components/_buttons/TopBar";
import { Col, Container, Image, Row } from "react-bootstrap";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function ProfilePage() {
  return (
    <MainLayout>
      <SEOManager
        title="Profile - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/profile"
      />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <Row className="m-4 m-lg-2 p-0">
          <Col lg={9} md={9} sm={12} xs={12}>
            <div>
              <EditProfileSection />
            </div>
            <div>
              <ChangePasswordSection />
            </div>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="d-none d-lg-block"
          ></Col>
        </Row>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.profile_edit_2}
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ProfilePage;
