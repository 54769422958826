import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button as AntButton } from "antd";

import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import { validationSchemas } from "../../../utils/validationSchemas";
import { ROUTES } from "../../../routes/constants";
import TopBar from "../../../components/_buttons/TopBar";
import RenderField from "../../../components/_fields/RenderField";
import { StaticImages } from "../../../utils/constants";
import { controller } from "../../../services/apiServices";

function SignupPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.signupSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      const payload = omit(data, ["confirmPassword"]);

      await dispatch(
        authController.createUser({
          payload: payload,
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error Creating User:", error);
    } finally {
    }
  };

  // Google Sign-In handler
  const handleGoogleSignIn = async () => {
    try {
      const response = await controller.auth.loginWithGoogle();
      if (response.status === 200) {
        const authUrl = response.url;
        // Navigate to the Google authentication URL
        window.location.href = authUrl;
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error with Google Sign-In:", error?.response || error);
      // Optionally, show an error notification to the user
    }
  };

  const seoData = getSEOConfig("signupPage");

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>
          <div>
            <Row className="m-4 m-lg-2 p-0">
              <Col lg={6} md={6} sm={12} xs={12} className={`rounded-5`}>
                <div className="w-100 p-lg-5 p-sm-2">
                  <h3 className="my-4">Welcome</h3>
                  <h6 className="my-4">
                    Signup to access the premium content of website
                  </h6>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {formFieldTypes.signupFields.map((field) => (
                      <RenderField
                        key={field.name}
                        field={field}
                        control={control}
                        errors={errors}
                      />
                    ))}

                    <AntButton
                      variant="solid"
                      color="default"
                      className="mt-3 fw-bold w-100"
                      htmlType="submit"
                      size="large"
                      loading={isLoading}
                    >
                      Sign Up
                    </AntButton>
                    <AntButton
                      variant="outlined"
                      size="large"
                      className="w-100 my-2"
                      icon={<Image fluid src={StaticImages.App.AppGoogle} />}
                      onClick={handleGoogleSignIn}
                    >
                      Sign up with Google
                    </AntButton>
                  </form>

                  <div className="my-3 d-flex justify-content-center align-items-center text-center">
                    <div>Already have an Account?</div>
                    <div>
                      <Button
                        variant="link"
                        onClick={() => navigate(ROUTES.AUTH.LOGIN)}
                        className="fw-bold text-decoration-none"
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="d-none d-lg-flex d-md-flex position-relative bg-sliceHeaven rounded-4"
              >
                <div className="w-100" style={{ paddingTop: "100%" }}>
                  {/* This padding maintains the 1:1 aspect ratio */}
                  <Image
                    fluid
                    className="position-absolute top-0 start-0 w-100 h-100 p-5"
                    src={StaticImages.App.AppDefaultWhite}
                    style={{ objectFit: "contain" }}
                    loading="lazy"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default SignupPage;
