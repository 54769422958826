import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { FaPlay } from "react-icons/fa6";
import styles from "./PodcastCard.module.css";

function PodcastCard({ podcast, index }) {
  const navigate = useNavigate();

  return (
    <>
      <Card
        key={podcast.id || index}
        // className={`border-0 mx-2 p-3 ${styles.podcastCard}`}
        className={`border-0 p-3`}
      >
        <Card.Img
          variant="top"
          src={podcast.image}
          style={{
            objectFit: "cover",
            height: "200px",
          }}
          className="rounded-3 h-100 w-100"
          loading="lazy"
        />
        <Card.Body className="d-flex flex-column p-2">
          <Card.Title as="h6" className={`${styles.truncateTitle} fw-bold`}>
            {podcast.name}
          </Card.Title>
          <div className="mt-auto">
            <Button
              as={motion.button}
              variant="eigengrau"
              size="sm"
              className="mt-2 p-2 d-flex align-items-center rounded-3"
              // whileHover={{ scale: 1.05 }}
              // whileTap={{ scale: 0.95 }}
              // transition={{
              //   type: "spring",
              //   stiffness: 300,
              //   damping: 20,
              // }}
            >
              <FaPlay className="mx-1" />
              <small>Watch Podcast</small>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PodcastCard;
