import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getUserCommentsLyrics = createApiThunk(
  "comment/getUserCommentsLyrics",
  { method: "GET", endpoint: "/user/get-user-comments-list" },
  ({ data, payload }) => {
    // Optional success handling
  }
);

export const addUserCommentLyrics = createApiThunk(
  "comment/addUserCommentLyrics",
  { method: "POST", endpoint: "/user/add-comment" },
  ({ data, resetForm }) => {
    showToast("success", data.message, 2);
    resetForm();
  }
);

const deleteUserCommentsLyrics = createApiThunk(
  "comment/deleteUserCommentsLyrics",
  { method: "DELETE", endpoint: "/user/delete-user-comment" },
  ({ data }) => {
    showToast("success", data.message, 2);
  }
);

export const commentThunks = {
  getUserCommentsLyrics,
  addUserCommentLyrics,
  deleteUserCommentsLyrics,
};
