// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuthUser = () => useContext(AuthContext);

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null,
    isAuthenticated: false,
    token: null,
  });

  // Simulate persistence with localStorage/sessionStorage
  useEffect(() => {
    const storedUser = localStorage.getItem("authUserContext");
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (response) => {
    const userData = {
      user: response,
      isAuthenticated: true,
      token: response.token,
    };
    setAuthUser(userData);

    localStorage.setItem("authUserContext", JSON.stringify(userData));
  };

  const logout = () => {
    setAuthUser({
      user: null,
      isAuthenticated: false,
      token: null,
    });
    localStorage.removeItem("authUserContext");
  };

  return (
    <AuthContext.Provider value={{ authUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
