import { createApiThunk } from "../../utils/thunkUtils";

/* Create thunks for each endpoint */
export const getAlbumsList = createApiThunk(
  "album/getArtistList",
  { method: "GET", endpoint: "/admin/album/songs" },
  ({ data, navigate, resetForm }) => {}
);
export const getAlbums = createApiThunk(
  "album/getArtistAlbums",
  { method: "GET", endpoint: "/user/artist-albums" },
  ({ data, navigate, resetForm }) => {}
);
export const getAlbumDetails = createApiThunk(
  "album/getAlbumDetails",
  { method: "GET", endpoint: "/user/albumDetails" },
  ({ data, navigate, resetForm }) => {}
);
export const getTopExploreAlbums = createApiThunk(
  "album/getTopExploreAlbums",
  { method: "GET", endpoint: "/user/get-top-explore-albums" },
  ({ data, navigate, resetForm }) => {}
);

export const getExploreAlbums = createApiThunk(
  "album/getExploreAlbum",
  { method: "GET", endpoint: "/user/get-explore-albums" },
  ({ data, navigate, resetForm }) => {}
);

export const getAlbumSongs = createApiThunk(
  "album/getAlbumSongs",
  { method: "GET", endpoint: "/user/get-album-songs" },
  ({ data, navigate, resetForm }) => {}
);

export const albumThunks = {
  getAlbumsList,
  getAlbums,
  getAlbumDetails,
  getTopExploreAlbums,
  getExploreAlbums,
  getAlbumSongs,
};
