import React from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa6";
import { Button as AntButton, Input, notification } from "antd";
import { useForm, Controller } from "react-hook-form";

import { StaticImages, StaticLinks } from "../../utils/constants";
import { controller } from "../../services/apiServices";
import { getConfig } from "../../services/apiUtils";

const FollowUs = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const images = [
    { src: StaticImages.FollowUs.Img1, alt: "Media 1" },
    { src: StaticImages.FollowUs.Img2, alt: "Media 2" },
    { src: StaticImages.FollowUs.Img3, alt: "Media 3" },
    { src: StaticImages.FollowUs.Img4, alt: "Media 4" },
    { src: StaticImages.FollowUs.Img5, alt: "Media 5" },
    { src: StaticImages.FollowUs.Img6, alt: "Media 6" },
    { src: StaticImages.FollowUs.Img7, alt: "Media 7" },
    { src: StaticImages.FollowUs.Img8, alt: "Media 8" },
    { src: StaticImages.FollowUs.Img9, alt: "Media 9" },
  ];

  const socialIcons = [
    { icon: <FaYoutube size={20} />, alt: "YouTube", path: "#" },
    {
      icon: <FaFacebook size={20} />,
      alt: "Facebook",
      path: StaticLinks.App.AppFacebookLink,
    },
    {
      icon: <FaInstagram size={20} />,
      alt: "Instagram",
      path: StaticLinks.App.AppInstagramLink,
    },
    { icon: <FaTwitter size={20} />, alt: "Twitter", path: "#" },
  ];

  const onSubmit = async (payload) => {
    try {
      const response = await controller.config.subscribeToNewsletter(
        payload,
        getConfig(),
        reset
      );
    } catch (error) {
      notification.error({
        message: "Subscription Failed",
        description: error.message || "An error occurred while subscribing.",
      });
    }
  };

  return (
    <Card className="border-0 py-4 bg-ghostWhite">
      <Row className="d-flex align-items-stretch g-4 m-0 p-0">
        {/* Left Side: Media Gallery */}
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <Row className="g-3 w-100 m-0 p-0">
            {images.map((image, index) => (
              <Col key={index} xs={4} sm={4} md={4} lg={4}>
                <Image
                  fluid
                  src={image.src}
                  alt={image.alt}
                  style={{ height: "100%", objectFit: "cover" }}
                  loading="lazy"
                />
              </Col>
            ))}
          </Row>
        </Col>

        {/* Right Side: Follow Us Section */}
        <Col
          xs={12}
          md={6}
          className="d-flex flex-column justify-content-center text-white p-5 bg-sliceHeaven rounded-4"
        >
          <h2 className="fw-bold my-3 text-center">Follow Us</h2>
          <p className="my-3 text-center">
            Follow us to keep up with the latest from us and our brands, updates
            from our teams, and the daily grind from around the web.
          </p>

          {/* Social Media Icons */}
          <div className="d-flex justify-content-center gap-3 my-3">
            {socialIcons.map((social, index) => (
              <Button
                key={index}
                variant="vibrantBlue"
                className="py-2 text-white"
                aria-label={social.alt}
                onClick={() => window.open(social.path, "_blank")}
              >
                {social.icon}
              </Button>
            ))}
          </div>

          {/* Subscription Form */}
          <form onSubmit={handleSubmit(onSubmit)} className="my-3">
            <p>Subscribe Now</p>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Enter a valid email address",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter your email"
                  size="large"
                  suffix={
                    <AntButton htmlType="submit" color="default" variant="text">
                      <Image
                        className="h-100"
                        src="/assets/static/telegram-svgrepo-com.svg"
                        loading="lazy"
                      />
                    </AntButton>
                  }
                />
              )}
            />
            {errors.email && (
              <p className="text-danger mt-2">{errors.email.message}</p>
            )}
          </form>
        </Col>
      </Row>
    </Card>
  );
};

export default FollowUs;
