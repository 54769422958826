import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import TrendingArtists from "../../../components/_sections/TrendingArtists";
import AllArtists from "../../../components/_sections/AllArtists";
import TopArtists from "../../../components/_sections/TopArtists";
import TopBar from "../../../components/_buttons/TopBar";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { GoogleAdSense } from "../../../utils/constants";

function ArtistsPage() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.artists_1}
          />
        </div>
        <div className="m-4 m-lg-2">
          <TopArtists />
        </div>

        <div className="m-4 m-lg-2">
          <TrendingArtists mode="light" />
        </div>

        <div className="m-4 m-lg-2">
          <AllArtists display={12} />
        </div>
      </Container>
    </MainLayout>
  );
}

export default ArtistsPage;
