import React from "react";
import { Container } from "react-bootstrap";

import MainLayout from "../../../layouts/MainLayout";
import TopPlaylists from "../../../components/_sections/TopPlaylists";
import ExplorePlaylists from "../../../components/_sections/ExplorePlaylists";
import GenresSection from "../../../components/_sections/GenresSection";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function PlaylistsPage() {
  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-4 m-lg-2">
            <TopPlaylists />
          </div>

          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.playlists_1}
            />
          </div>

          <div className="m-4 m-lg-2">
            <ExplorePlaylists />
          </div>

          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.playlists_2}
            />
          </div>

          <div className="m-0 m-lg-2">
            <GenresSection background="sliceHeaven" color="ghostWhite" />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default PlaylistsPage;
