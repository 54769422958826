import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Tag, Alert } from "antd";

import { getConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import { formFieldTypes } from "../../../utils/formFieldTypes";
import { validationSchemas } from "../../../utils/validationSchemas";
import RenderField from "../../../components/_fields/RenderField";
import {
  FaLock,
  FaRegCircleCheck,
  FaExclamationCircle,
  FaRegCircleXmark,
} from "react-icons/fa6";
import { ROUTES } from "../../../routes/constants";
import TopBar from "../../../components/_buttons/TopBar";
import { FaArrowLeft } from "react-icons/fa6";

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const { isLoading } = useSelector((state) => state.auth);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas.resetPasswordSchema),
    mode: "onChange",
  });

  const [responseStatus, setResponseStatus] = useState(null); // null | "success" | "fail"

  const onSubmit = async (payload) => {
    try {
      const { password, confirmPassword, ...rest } = payload;
      const newPayload = { ...rest, resetToken, newPassword: confirmPassword };

      const { status } = await dispatch(
        authController.resetPassword({
          payload: newPayload,
          config: getConfig(),
          resetForm: reset,
          navigate,
        })
      ).unwrap();

      // Conditional render based on API response
      if (status === 200) {
        setResponseStatus("success");
      } else {
        setResponseStatus("fail");
      }
    } catch (error) {
      console.error("Error Resetting Password:", error);
      setResponseStatus("fail");
    }
  };

  const seoData = getSEOConfig("resetPasswordPage");

  return (
    <MainLayout>
      <SEOManager {...seoData} />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <Row className="m-4 m-lg-2 p-0">
          <Col lg={2} md={2} sm={0} xs={0}></Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            {responseStatus === null ? (
              <div className="w-100 p-lg-5 p-md-3 p-sm-2">
                <div className="d-flex justify-content-center py-2">
                  <Tag bordered={false} className="shadow px-3 py-2 rounded-3">
                    <FaLock />
                  </Tag>
                </div>
                <h3 className="my-4 fw-bold">Set New Password</h3>
                <h6 className="my-4">
                  Your new password must be different from previously used
                  passwords.
                </h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {formFieldTypes.resetPasswordFields.map((field) => (
                    <RenderField
                      key={field.name}
                      field={field}
                      control={control}
                      errors={errors}
                    />
                  ))}
                  <Button
                    variant="solid"
                    color="default"
                    className="mt-3 fw-bold w-100"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Reset Password
                  </Button>
                </form>
              </div>
            ) : responseStatus === "success" ? (
              <div className="w-100 p-lg-5 p-md-3 p-sm-2 text-center">
                <div className="d-flex justify-content-center py-2">
                  <Tag bordered={false} className="shadow px-3 py-2 rounded-3">
                    <FaRegCircleCheck />
                  </Tag>
                </div>
                <h3 className="my-4 fw-bold">Password Reset Successful</h3>
                <h6 className="my-4">
                  Your password has been successfully reset. You can now log in
                  with your new credentials.
                </h6>
              </div>
            ) : (
              <div className="w-100 p-lg-5 p-md-3 p-sm-2 text-center">
                <div className="d-flex justify-content-center py-2">
                  <Tag bordered={false} className="shadow px-3 py-2 rounded-3">
                    <FaRegCircleXmark />
                  </Tag>
                </div>
                <h3 className="my-4 fw-bold">Password Reset Failed</h3>
                <h6 className="my-4">
                  Something went wrong. Please try again later or contact
                  support if the issue persists.
                </h6>
              </div>
            )}

            {/* Common "Back to Login" Button */}
            <div className="d-flex justify-content-center align-items-center">
              <Button
                type="text"
                className="fw-bold"
                size="large"
                onClick={() => navigate(ROUTES.AUTH.LOGIN)}
              >
                <FaArrowLeft className="me-2" />
                Back to Login
              </Button>
            </div>
          </Col>
          <Col lg={2} md={2} sm={0} xs={0}></Col>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default ResetPasswordPage;
