import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  List,
  Spin,
  Empty,
  Typography,
  Divider,
  Avatar,
} from "antd";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

import { searchController } from "../../features/search/searchSlice";
import { getConfig } from "../../services/apiUtils";
import { stringUtils } from "../../utils/utilities";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";
import _ from "lodash";

const { Title } = Typography;

function AppSearchBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const { searchResult, isLoading, error } = useSelector(
    (state) => state.search
  );

  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // Debounced search function
  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      if (query.trim()) {
        try {
          await dispatch(
            searchController.getSearch({
              payload: { query },
              config: getConfig(),
            })
          ).unwrap();
        } catch (error) {
          console.error("Failed to fetch search results:", error);
        }
      }
    }, 300),
    [dispatch]
  );

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.trim()) {
      fetchSearchResults(value);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  // Trigger search on pressing Enter
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue.trim()) {
      navigate(`/search?query=${searchValue.trim()}`);
      setShowDropdown(false);
    }
  };

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Navigate and close dropdown
  const handleItemClick = (route) => {
    navigate(route);
    setShowDropdown(false);
  };

  return (
    <div className="w-100 position-relative">
      {/* Search Input */}
      <Input
        placeholder="What do you feel like listening to right now?"
        value={searchValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        suffix={
          <Button
            variant="solid"
            color="default"
            shape="circle"
            onClick={() =>
              searchValue.trim() &&
              navigate(`/search?query=${searchValue.trim()}`)
            }
          >
            <SearchOutlined />
          </Button>
        }
        className="rounded-pill"
      />

      {/* Search Results Dropdown */}
      {showDropdown && searchValue.trim() && (
        <div
          ref={dropdownRef}
          className="position-absolute w-100 mt-2 rounded-5 shadow"
          style={{
            zIndex: 9999,
            top: "100%",
            backgroundColor: "#fff",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <Spin className="d-flex justify-content-center p-4" />
          ) : error ? (
            <Empty description="Failed to load results" />
          ) : searchResult ? (
            <div className="p-4">
              {/* Matched Artists */}
              {searchResult?.matched_artists?.length > 0 && (
                <>
                  <Title level={5} className="d-flex justify-content-between">
                    <span>Artist</span>
                    {/* <span>Views</span> */}
                  </Title>
                  <List
                    dataSource={searchResult?.matched_artists}
                    renderItem={(artist) => (
                      <List.Item
                        className="border-0"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", // Align items vertically
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleItemClick(
                            generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                              artistName: _.kebabCase(artist?.artist_name),
                              artistId: artist?.artist_id,
                            })
                          )
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          {/* Avatar or Image */}
                          <Avatar
                            src={artist?.downloaded_image_link}
                            alt={artist?.artist_name}
                            size={40} // You can adjust the size as needed
                            shape="square"
                          />
                          <span>{artist?.artist_name}</span>
                        </div>
                        <span>{artist?.popular_album}</span>
                      </List.Item>
                    )}
                    className="rounded-4"
                  />

                  <Divider />
                </>
              )}

              {/* Matched Songs */}
              {searchResult?.matched_songs?.length > 0 && (
                <>
                  <Title level={5} className="d-flex justify-content-between">
                    <span>Songs</span>
                    <span>Artist</span>
                  </Title>
                  <List
                    dataSource={searchResult?.matched_songs}
                    renderItem={(song) => (
                      <List.Item
                        className="border-0"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", // Align items vertically
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleItemClick(
                            generateDynamicRoute(ROUTES.MAIN.VIEW_SONG_LYRICS, {
                              songName: _.kebabCase(song?.song_name),
                              isrcKey: song?.isrc_code,
                            })
                          )
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          {/* Avatar or Image */}
                          <Avatar
                            src={song?.downloaded_image_link}
                            alt={song?.song_name}
                            size={40} // Adjust the size as needed
                            shape="square"
                          />
                          <span>{song?.song_name}</span>
                        </div>
                        <span>{song?.artist_name}</span>

                        {/* <span>{song?.views?.toLocaleString()}</span> */}
                      </List.Item>
                    )}
                    className="rounded-4"
                  />

                  <Divider />
                </>
              )}

              {/* Matched Albums */}
              {searchResult?.matched_albums?.length > 0 && (
                <>
                  <Title level={5} className="d-flex justify-content-between">
                    <span>Album</span>
                    <span>Songs</span>
                  </Title>
                  <List
                    dataSource={searchResult?.matched_albums}
                    renderItem={(album) => (
                      <List.Item
                        className="border-0"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", // Align items vertically
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleItemClick(
                            generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                              albumName: _.kebabCase(album?.album_name),
                              albumId: album?.album_id,
                            })
                          )
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          {/* Avatar or Album Image */}
                          <Avatar
                            src={album?.downloaded_image_link}
                            alt={album?.album_name}
                            size={40} // Adjust size as needed
                            shape="square"
                          />
                          <span>
                            {stringUtils.sanitizeName(album?.album_name)}
                          </span>
                        </div>
                        <span>{album?.song_count} Songs</span>
                      </List.Item>
                    )}
                    className="rounded-4"
                  />
                </>
              )}
            </div>
          ) : (
            <Empty description="No results found" />
          )}
        </div>
      )}
    </div>
  );
}

export default AppSearchBar;
