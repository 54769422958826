import React from "react";
import { Container } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import LatestMusicNews from "../../../components/_sections/LatestMusicNews";
import TopBar from "../../../components/_buttons/TopBar";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { GoogleAdSense } from "../../../utils/constants";

function NewsPage() {
  const seoData = getSEOConfig("newsPage");

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.news_1}
            />
          </div>

          <div className="m-4 m-lg-2">
            <LatestMusicNews display={12} />
          </div>

          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.news_2}
            />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default NewsPage;
