import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

import { songController } from "../../features/song/songSlice";
import { getConfig } from "../../services/apiUtils";
import { ErrorAlert } from "../_alerts/UIAlerts";
import SongCard from "../_cards/SongCard";

function TopSongs({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();

  const { topSongs, isLoading, error } = useSelector(
    (state) => state.song || {}
  );

  useEffect(() => {
    dispatch(
      songController.getTopSongs({
        payload: {},
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch top songs:", error));
  }, [dispatch, genreType]);

  const randomSongs = useMemo(() => {
    return topSongs?.length > 0
      ? [...topSongs].sort(() => Math.random() - 0.5).slice(0, display)
      : [];
  }, [topSongs, display]);

  return (
    <div className="my-1 my-lg-4">
      <div className="my-1 my-lg-4">
        <h1 className="fw-bold">Top Songs</h1>
      </div>
      <Row className="g-3 g-lg-4 m-0 p-0">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "25vh", width: "100%" }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        ) : error ? (
          <>
            <ErrorAlert description={error} />
          </>
        ) : randomSongs.length > 0 ? (
          <Row className="g-3 g-lg-4 bg-ghostWhite m-0 p-0">
            {randomSongs.map((song, index) => (
              <Col lg={3} md={4} sm={12} xs={12} key={index}>
                <SongCard type="grid" song={song} index={index} />
              </Col>
            ))}

            {/* <div className="ms-auto d-flex align-items-center float-end">
              <span className="fw-bold">See All</span>
              <Button
                variant="eigengrau"
                size="sm"
                className="fw-bold fs-6 mx-2 rounded-circle"
              >
                <MdArrowOutward />
              </Button>
            </div> */}
          </Row>
        ) : (
          <p className="text-center">No top songs available at the moment.</p>
        )}
      </Row>
    </div>
  );
}

export default TopSongs;
