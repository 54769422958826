import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getAllAdminPlaylists = createApiThunk(
  "playlist/getAllAdminPlaylists",
  { method: "GET", endpoint: "/user/get-all-admin-playlist" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getPlaylistSongs = createApiThunk(
  "playlist/getPlaylistSongs",
  { method: "GET", endpoint: "/user/get-playlist-songs" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getUserMyPlaylists = createApiThunk(
  "playlist/getUserMyPlaylists",
  { method: "GET", endpoint: "/user/get-all-user-playlist" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getUserPlaylistSongs = createApiThunk(
  "playlist/getUserPlaylistSongs",
  { method: "GET", endpoint: "/user/get-playlist-songs-user" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const addUserPlaylist = createApiThunk(
  "playlist/addUserPlaylist",
  { method: "POST", endpoint: "/user/create-user-playlist" },
  ({ data, resetForm }) => {
    showToast("success", data.message, 2);
    resetForm();
  }
);

export const deleteUserPlaylist = createApiThunk(
  "playlist/deleteUserPlaylist",
  { method: "DELETE", endpoint: "/user/delete-user-playlist" },
  ({ data }) => {
    showToast("success", data.message, 2);
  }
);

export const playlistThunks = {
  getAllAdminPlaylists,
  getPlaylistSongs,
  getUserMyPlaylists,
  getUserPlaylistSongs,
  addUserPlaylist,
  deleteUserPlaylist,
};
