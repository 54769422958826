// src/components/DynamicRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken, isTokenValid } from "../utils/authHelpers";
import { ROUTES } from "./constants";

const ProtectedRoute = ({
  element: Component,
  type = "private",
  redirectTo,
}) => {
  const authToken = getAuthToken();
  const isAuthenticated = authToken && isTokenValid(authToken);

  if (type === "private") {
    // For private routes: only accessible if authenticated
    return isAuthenticated ? (
      <Component />
    ) : (
      <Navigate to={redirectTo || ROUTES.AUTH.LOGIN} replace />
    );
  } else {
    // For public routes: only accessible if NOT authenticated
    return isAuthenticated ? (
      <Navigate to={redirectTo || ROUTES.MAIN.HOME} replace />
    ) : (
      <Component />
    );
  }
};

export default ProtectedRoute;
