import React from "react";
import PropTypes from "prop-types";
import { Card, Button } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import styles from "./NewsCard.module.css";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { dateUtils } from "../../utils/utilities";
import { StaticImages } from "../../utils/constants";

const NewsCard = ({ news, index }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (news?._id) {
      const dynamicRoute = generateDynamicRoute(ROUTES.MAIN.NEWS_DETAILS, {
        newsId: news._id,
      });
      navigate(dynamicRoute);
    }
  };

  return (
    <Card
      key={news._id || index}
      // className={`border-0 mx-2 ${styles.newsCard}`}
      className={`border-0`}
      onClick={handleNavigate}
      role="button"
    >
      <Card.Img
        variant="top"
        src={news?.coverImg || StaticImages.App.AppDefaultS}
        alt={news?.title || "News image"}
        className="rounded-3"
        style={{
          objectFit: "cover",
          height: "150px",
        }}
        loading="lazy"
      />

      <Card.Body className="d-flex flex-column p-2">
        {/* News Meta Information */}
        <span className={`text-muted my-2 ${styles.spanSmallText}`}>
          <small className="float-start">
            {news?.author || "Unknown Author"}
          </small>
          <small className="float-end">
            {news?.publishDate ? dateUtils.formatDate(news.publishDate) : "N/A"}
          </small>
        </span>
        <div>
          <Card.Title as="h6" className={`${styles.truncateTitle} fw-bold`}>
            {news?.title || "Untitled News"}
          </Card.Title>
        </div>

        <div>
          {/* Action Button */}
          <Button
            as={motion.button}
            variant="light"
            size="sm"
            className="fw-bold fs-6 ms-auto rounded-circle float-end"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
            }}
          >
            <MdArrowOutward />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

// PropTypes for type safety
NewsCard.propTypes = {
  news: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    id: PropTypes.string,
    coverImg: PropTypes.string,
    author: PropTypes.string,
    publishDate: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default NewsCard;
