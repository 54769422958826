import { jwtDecode } from "jwt-decode";

import { showToast } from "../components/_toasts/notificationUtils";
import { ROUTES } from "../routes/constants";

export const setAuthToken = (authToken) => {
  localStorage.setItem("auth-token", authToken);
};

export const setAuthUser = (authUser) => {
  localStorage.setItem("auth-user", JSON.stringify(authUser));
};

export const getAuthToken = () => {
  let authToken = localStorage.getItem("auth-token");
  return authToken;
};

export const getAuthUser = () => {
  let authUser = localStorage.getItem("auth-user");
  return authUser;
};

export const removeAuthToken = () => {
  localStorage.removeItem("auth-token");
  localStorage.clear();
};

export const removeAuthUser = () => {
  localStorage.removeItem("auth-user");
  localStorage.clear();
};

export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // in seconds
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

// The final isAuthenticated function that returns the object with token and user info
export const isAuthenticated = () => {
  const token = getAuthToken();
  const user = getAuthUser();

  // If token is valid and user details exist, return the authentication object
  if (token && isTokenValid(token) && user) {
    return {
      isUserAuthenticated: true,
      token: token,
      user: user,
    };
  }

  // If not authenticated, return an object indicating that
  localStorage.clear();
  return {
    isUserAuthenticated: false,
    token: null,
    user: null,
  };
};

// handle authentication error and navigate to the signin if status 401 from api is returned
export const handleAuthError = (errorMessage) => {
  // console.log("Authentication Error:", errorMessage);
  // showToast("error", errorMessage, 2);
  localStorage.clear();
  // window.location.href = ROUTES.AUTH.LOGIN;
};
