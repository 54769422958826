// src/services/apiService.js
import axios from "axios";
import {
  showNotification,
  showToast,
} from "../components/_toasts/notificationUtils";
import { ROUTES } from "../routes/constants";
import { setAuthToken } from "../utils/authHelpers";
import { makeRequest } from "./apiRequests";
import { handleApiError } from "./apiUtils";

/* Auth Section */
async function loginUser(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/login-user",
      payload,
      config
    );
    if (status === 200 && data) {
      showToast("success", data.message, 2);
      setAuthToken(data.data.token);
      navigate(ROUTES.MAIN.HOME);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function loginWithGoogle(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/auth-url",
      payload,
      config
    );
    if (status === 200 && data) {
      // showToast("success", data.message, 2);
      // setAuthToken(data.data.token);
      // navigate(ROUTES.MAIN.HOME);
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function completeGoogleSignIn(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/oauth2callback",
      payload,
      config
    );
    if (status === 200 && data) {
      // showToast("success", data.message, 2);
      // setAuthToken(data.data.token);
      // navigate(ROUTES.MAIN.HOME);
      // resetForm();
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function createUser(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/create-user",
      payload,
      config
    );
    if (status === 201) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function verifyUser(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/verify-user",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function forgetPassword(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/forget-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function resetPassword(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/reset-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      navigate(ROUTES.AUTH.LOGIN);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function changeUserPassword(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/change-user-password",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function editUserProfile(payload = {}, config = {}, resetForm, navigate) {
  try {
    const { data, status } = await makeRequest(
      "PUT",
      "/user/edit-user",
      payload,
      config
    );
    if (status === 200) {
      showToast("success", data.message, 2);
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

async function getUserProfile(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/user-profile",
      payload,
      config
    );
    if (status === 200) {
      // showToast("success", data.message, 2);
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getFavoriteSongsUser(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/all-favourite-songs",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

async function getFavoriteSongUserById(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-favourite-song-by-id",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

async function addSongToFavourite(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-to-favourite-list",
      payload,
      config
    );
    if (status === 201) {
      // showNotification("success", data.message);
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

async function removeSongFromFavourite(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      "/user/remove-from-favourite-list",
      payload,
      config
    );
    if (status === 200) {
      // showNotification("success", data.message);
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

async function addSongToRecentlyPlayed(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-to-recent-played-songs",
      payload,
      config
    );
    if (status === 201) {
      // showNotification("success", data.message);
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

async function getRecentlyPlayedSongs(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-recent-played-songs",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error, navigate);
  }
}

/* Lyrics Section */
async function getSearchResult(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/get-search-lyrics",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getTrackLyrics(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/get-lyrics",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

/* Others Section */
async function getTestimonialsList(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-testimonial",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getNewsList(payload = {}, config = {}, navigate) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-news-list",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function getNewsDetails(payload = {}, config = {}, navigate) {
  try {
    let { newsId } = payload;
    const { data, status } = await makeRequest(
      "GET",
      `/user/get-news?newsId=${newsId}`,
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

async function submitUserFeedback(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/submit-user-feedback",
      payload,
      config
    );
    if (status === 201) {
      resetForm();
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export const fetchGenres = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-genres",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCountryCodesAPI = async (payload = {}, config = {}) => {
  try {
    // Make the API request
    const response = await axios.get(
      "https://restcountries.com/v3.1/all",
      config
    );

    if (response.status === 200) {
      return response;
    } else {
      console.error("API Error:", response.statusText);
    }
  } catch (error) {
    console.error("API Error:", error.message);
    // handleApiError(error);
  }
};

export const subscribeToNewsletter = async (
  payload = {},
  config = {},
  resetForm
) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/subscribe-newsletter",
      payload,
      config
    );
    if (status === 201) {
      showNotification("success", data.message);
      resetForm();
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const getBannerImage = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-banner",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error);
  }
};

export const getSearchSong = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/search-song",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};

export async function addSongUserPlayList(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-song-to-playlist",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export async function deleteSongUserPlayList(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "PATCH",
      "/user/remove-user-playlist-song",
      payload,
      config
    );
    if (data.status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export const contributeLyricsUser = async (
  payload = {},
  config = {},
  resetForm,
  navigate
) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/contribute-lyrics",
      payload,
      config
    );
    if (data.success === true) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
};

export const addUserCommentLyrics = async (
  payload = {},
  config = {},
  resetForm,
  navigate
) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-comment",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
};

export const deleteUserCommentLyrics = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      "/user/delete-user-comment",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const reactLikeDislikeComment = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/like-dislike-quiz-comment",
      payload,
      config
    );
  } catch (error) {
    handleApiError(error);
  }
};

export const getQuiz = async (payload = {}, config = {}) => {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/get-quiz",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      // showToast("error", data.message, 2);
    }
  } catch (error) {
    // handleApiError(error);
  }
};

export async function getUserCommentLyrics(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "GET",
      "/user/get-user-comments-list",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

export async function addUserCommentQuiz(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/add-quiz-comment",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export async function getUserCommentQuiz(
  payload = {},
  config = {},
  resetForm,
  navigate
) {
  try {
    const { data, status } = await makeRequest(
      "POST",
      "/user/get-quiz-comment-by-quiz-id",
      payload,
      config
    );
    if (data.success == true) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error, navigate);
  }
}

export async function deleteUserCommentQuiz(payload = {}, config = {}) {
  try {
    const { data, status } = await makeRequest(
      "DELETE",
      "/user/delete-quiz-comment",
      payload,
      config
    );
    if (status === 200) {
      return data;
    } else {
      showToast("error", data.message, 2);
    }
  } catch (error) {
    handleApiError(error);
  }
}

export const controller = {
  auth: {
    createUser,
    loginUser,
    loginWithGoogle,
    completeGoogleSignIn,
    forgetPassword,
    resetPassword,
    changeUserPassword,
    getUserProfile,
    editUserProfile,
    getSearchResult,
    getTrackLyrics,
    getTestimonialsList,
    getNewsList,
    getNewsDetails,
    submitUserFeedback,
    verifyUser,
    getFavoriteSongsUser,
    getFavoriteSongUserById,
    addSongToFavourite,
    removeSongFromFavourite,
    getSearchSong,
    addSongToRecentlyPlayed,
    getRecentlyPlayedSongs,
  },
  lyrics: {
    contributeLyricsUser,
    getUserCommentLyrics,
    addUserCommentLyrics,
    deleteUserCommentLyrics,
    reactLikeDislikeComment,
  },
  quiz: {
    getQuiz,
    addUserCommentQuiz,
    getUserCommentQuiz,
    deleteUserCommentQuiz,
  },
  playlist: {
    addSongUserPlayList,
    deleteSongUserPlayList,
  },
  config: {
    fetchGenres,
    fetchCountryCodesAPI,
    subscribeToNewsletter,
    getBannerImage,
  },
};

/**
 * Makes a GET / POST / PUT / DELETE / PATCH request to the specified endpoint with optional data.
 *
 * @param {string} endpoint - The endpoint to send the GET / POST / PUT / DELETE / PATCH request to.
 * @param {Object} [data={}] - Optional data to send in the request body.
 * @param {Object} [config={}] - Optional configuration for the request.
 * @returns {Promise} - Axios promise for the POST request.
 */
