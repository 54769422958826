import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Layout, Spin, Tabs, Typography } from "antd";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense } from "../../../utils/constants";
import { artistController } from "../../../features/artist/artistSlice";
import { getAuthConfig, getConfig } from "../../../services/apiUtils";
import AlbumCard from "../../../components/_cards/AlbumCard";
import SongCard from "../../../components/_cards/SongCard";
import { isAuthenticated } from "../../../utils/authHelpers";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

function ViewArtistBioPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { artistId } = useParams();
  const { artistBioWithSongs, isLoading, error } = useSelector(
    (state) => state.artist
  );
  const [selectedTab, setSelectedTab] = useState("about");

  const fetchArtistBio = useCallback(async () => {
    if (artistId) {
      try {
        await dispatch(
          artistController.getArtistBioDetails({
            payload: { artist_id: artistId },
            config: getConfig(),
            navigate,
          })
        ).unwrap();
      } catch (error) {
        console.error("Error fetching artist bio:", error);
      }
    }
  }, [dispatch, artistId, navigate]);

  // Function to handle Add to Favorite
  const handleAddToFavorite = async (artist_id) => {
    try {
      await dispatch(
        artistController.addFavoriteArtistUser({
          payload: { artist_id: artist_id },
          config: getAuthConfig(),
          navigate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error handling favorites:", error);
    }
  };

  useEffect(() => {
    fetchArtistBio();
  }, [fetchArtistBio]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.artist_bio_1}
          />
        </div>

        <Row className="m-4 m-lg-2 p-0 g-3 g-lg-4">
          <Layout style={{ minHeight: "100vh", background: "#f9f9f9" }}>
            <Content>
              <Row className="m-0 p-0 g-3 g-lg-4">
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div>
                    <Image
                      src={artistBioWithSongs?.downloaded_image_link}
                      alt="Artist Avatar"
                      className="w-100 rounded-3"
                      loading="lazy"
                    />
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={12}>
                  <Spin spinning={isLoading} tip="Loading...">
                    <Title level={1}>{artistBioWithSongs?.artist_name}</Title>

                    {isAuthenticated().isUserAuthenticated && (
                      <Button
                        variant="solid"
                        color="default"
                        className="fw-bold"
                        onClick={() =>
                          handleAddToFavorite(artistBioWithSongs?.artist_id)
                        }
                      >
                        Add to Favourite
                      </Button>
                    )}

                    <Tabs
                      activeKey={selectedTab}
                      onChange={handleTabChange}
                      className="my-2 bg-white p-3 p-lg-4 rounded-4"
                    >
                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "about" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "about" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            About
                          </Button>
                        }
                        key="about"
                      >
                        <Title level={2}>Artist Bio</Title>
                        <Paragraph>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: artistBioWithSongs?.artist_description,
                            }}
                          />
                        </Paragraph>
                        <div className="m-2">
                          <Row className="g-4 my-2">
                            <Title level={2}>Popular Albums</Title>

                            {artistBioWithSongs?.topAlbums?.map(
                              (album, index) => (
                                <Col key={index} lg={6} md={6} sm={12} xs={12}>
                                  <AlbumCard
                                    index={index}
                                    album={album}
                                    type="card"
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                          <Row className="g-4 my-2">
                            <Title level={2}>Popular Songs</Title>

                            {artistBioWithSongs?.mostPopularSongs?.map(
                              (song, index) => (
                                <Col
                                  key={index}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <SongCard
                                    index={index}
                                    song={song}
                                    type="list"
                                  />
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "albums" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "albums" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            Albums
                          </Button>
                        }
                        key="albums"
                      >
                        <Title level={2}>Artist Bio</Title>
                        <Paragraph>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: artistBioWithSongs?.artist_description,
                            }}
                          />
                        </Paragraph>
                        <Title level={2}>Albums</Title>
                        <Row className="g-4 my-2">
                          {artistBioWithSongs?.topAlbums?.map(
                            (album, index) => (
                              <Col key={index} lg={6} md={6} sm={12} xs={12}>
                                <AlbumCard
                                  index={index}
                                  album={album}
                                  type="card"
                                />
                              </Col>
                            )
                          )}
                        </Row>{" "}
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <Button
                            variant={
                              selectedTab === "songs" ? "solid" : "outline"
                            }
                            color={
                              selectedTab === "songs" ? "default" : "white"
                            }
                            className="fw-bold"
                          >
                            Songs
                          </Button>
                        }
                        key="songs"
                      >
                        <Title level={2}>Artist Bio</Title>
                        <Paragraph>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: artistBioWithSongs?.artist_description,
                            }}
                          />
                        </Paragraph>
                        <Title level={2}>Songs</Title>
                        <Row className="g-4 my-2">
                          {artistBioWithSongs?.mostPopularSongs?.map(
                            (song, index) => (
                              <Col key={index} lg={12} md={12} sm={12} xs={12}>
                                <SongCard
                                  index={index}
                                  song={song}
                                  type="list"
                                />
                              </Col>
                            )
                          )}
                        </Row>{" "}
                      </Tabs.TabPane>
                    </Tabs>
                  </Spin>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Row>
      </Container>
    </MainLayout>
  );
}

export default ViewArtistBioPage;
