import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Avatar, Button, Grid, Spin, Tag, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCrown } from "react-icons/fa6";
import { MdQueueMusic } from "react-icons/md";
import { EditOutlined } from "@ant-design/icons";

import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import SEOManager from "../../../utils/SEOManager";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { getAuthConfig } from "../../../services/apiUtils";
import { authController } from "../../../features/auth/authSlice";
import MyLyrics from "../../../components/_sections/MyLyrics";
import FavouriteArtists from "../../../components/_sections/FavouriteArtists";
import { ROUTES } from "../../../routes/constants";
import { formatStringType } from "../../../utils/helpers";
import { ErrorAlert } from "../../../components/_alerts/UIAlerts";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { controller } from "../../../services/apiServices";
import SongCard from "../../../components/_cards/SongCard";

const { useBreakpoint } = Grid;

function ProfilePreviewPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screens = useBreakpoint();

  // Dynamically set level based on screen size
  const getLevel = () => {
    if (screens.xs) return 4; // Extra small screens
    if (screens.sm) return 3; // Small screens
    if (screens.md) return 2; // Medium screens
    return 1; // Large screens and above
  };

  // Dynamically set avatar size based on screen size
  const getSize = () => {
    if (screens.xs) return 75; // Extra small screens
    if (screens.sm) return 80; // Small screens
    if (screens.md) return 100; // Medium screens
    return 200; // Large screens
  };

  const { isLoading, error } = useSelector((state) => state.auth);

  const [userProfile, setUserProfile] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [lyricsContributed, setLyricsContributed] = useState(null);
  const [isLoadingPlayedSong, setIsLoadingPlayedSong] = useState(false);
  const [recentlyPlayedSongs, setRecentlyPlayedSongs] = useState(null);

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await dispatch(
        authController.getUserProfile({ config: getAuthConfig(), navigate })
      ).unwrap();
      setUserProfile(data?.user);
      setLyricsContributed(data?.contributionsCount);
    } catch (error) {
      console.error("Error Fetching User Profile:", error);
    }
  }, [dispatch, navigate]);

  const fetchRecentlyPlayedSongs = async () => {
    setIsLoadingPlayedSong(true);
    try {
      const { status, data } = await controller.auth.getRecentlyPlayedSongs(
        {},
        getAuthConfig()
      );

      if (status === 200) {
        setRecentlyPlayedSongs(data);
        setIsLoadingPlayedSong(false);
      } else {
        setIsLoadingPlayedSong(false);
      }
    } catch (error) {
      setIsLoadingPlayedSong(false);
      console.error("Error Fetching Recently Played Songs:", error);
    } finally {
      setIsLoadingPlayedSong(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchRecentlyPlayedSongs();
  }, [fetchUserProfile]);

  return (
    <MainLayout>
      <SEOManager
        title="Profile - LyricsWeb"
        ogUrl="www.lyricsweb.com/auth/profile"
      />
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <Row className="m-0 p-0 justify-content-center">
          {isLoading || !userProfile ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "25vh", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <ErrorAlert description={error} />
          ) : (
            <>
              {/* Avatar Column */}
              <Col
                lg={3}
                md={3}
                sm={4}
                xs={4}
                className="d-flex justify-content-center"
              >
                <Avatar
                  src={userProfile?.avatar || StaticImages.App.AppDefaultS}
                  alt="User Avatar"
                  size={getSize()} // Use dynamic size
                  className={`mb-3 border-4 border-hotPink`}
                />
              </Col>

              {/* User Info Column */}
              <Col
                lg={8}
                md={8}
                sm={8}
                xs={8}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex align-items-center">
                    <Typography.Title
                      level={getLevel()}
                      className="mb-0"
                      style={{ margin: 0 }}
                    >
                      {formatStringType(userProfile?.username) || "Username"}{" "}
                    </Typography.Title>
                    <FaCrown className="text-hotPink fs-1 mx-2" />
                  </div>
                  <Button
                    variant="solid"
                    color="default"
                    size="large"
                    className="mt-3 ms-3 d-none d-lg-block d-md-block" // Shows button on mobile and small screens
                    onClick={() => navigate(ROUTES.AUTH.PROFILE)}
                  >
                    <EditOutlined /> Edit Profile
                  </Button>
                </div>

                <div className="d-flex align-items-start my-3">
                  <div>
                    <Tag
                      bordered={false}
                      color="error"
                      className="fw-bold rounded-pill fs-6 p-2 ms-2"
                    >
                      Points {userProfile?.points || 0}
                    </Tag>
                  </div>
                  <div className="d-flex flex-column d-none d-lg-block">
                    <Typography.Paragraph className="d-flex align-items-center mb-1">
                      <MdQueueMusic className="fs-4 mx-2" />
                      Lyrics Contributed: {lyricsContributed || 0}
                    </Typography.Paragraph>
                    {/* <Typography.Paragraph className="d-flex align-items-center mb-0">
                      <FaRegCommentDots className="fs-4 mx-2" />
                      Comments Received: <strong>25</strong>
                    </Typography.Paragraph> */}
                  </div>
                </div>
              </Col>

              <div className="d-block d-sm-none">
                <Button
                  variant="solid"
                  color="default"
                  size="large"
                  className="mt-3 ms-3" // Shows button on mobile and small screens
                  onClick={() => navigate(ROUTES.AUTH.PROFILE)}
                >
                  <EditOutlined /> Edit Profile
                </Button>
              </div>
            </>
          )}
        </Row>

        <div className="m-4 m-lg-2">
          <MyLyrics />
        </div>

        {/* <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.profile_preview_1}
          />
        </div> */}

        <div className="m-4 m-lg-2 my-2 my-lg-4">
          <FavouriteArtists />
        </div>

        {/* <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.profile_preview_2}
          />
        </div> */}

        <div className="m-4 m-lg-2 my-2 my-lg-4">
          <div className="my-4">
            <div className="d-flex justify-content-between align-items-center my-3">
              <h1 className="fw-bold mb-0">Recently Played Songs</h1>
            </div>

            <Row className="g-4 m-0 p-0">
              {isLoadingPlayedSong ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "25vh", width: "100%" }}
                >
                  <Spin tip="Loading..." size="large" />
                </div>
              ) : recentlyPlayedSongs?.length > 0 ? (
                <>
                  {recentlyPlayedSongs?.map((song, index) => (
                    <Col lg={3} md={4} sm={12} xs={12} key={index}>
                      <SongCard type="grid" song={song} index={index} />
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  <p className="text-center">
                    No Recently Played Songs available at the moment.
                  </p>
                </>
              )}
            </Row>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
}

export default ProfilePreviewPage;
