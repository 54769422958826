import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEOManager from "../../../utils/SEOManager";
import { getSEOConfig } from "../../../utils/seoConfig";
import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import RenderField from "../../../components/_fields/RenderField";
import { GoogleAdSense } from "../../../utils/constants";
import AddLyricsSection from "./AddLyricsSection";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function AddNewSongLyricsPage() {
  const seoData = getSEOConfig("addLyricsPage");

  return (
    <>
      <MainLayout>
        <SEOManager {...seoData} />
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>
          <Row className="m-4 m-lg-2 p-0">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Container fluid>
                <h1 className="my-4 fw-bold">Contribute New Lyrics</h1>
                <p className="my-4 fw-light">
                  Join us in expanding the ultimate lyrics database online!
                  Kindly complete the form below and submit your contribution:
                </p>
                <AddLyricsSection />
              </Container>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <GoogleAd
                client={GoogleAdSense.client}
                slot={GoogleAdSense.slot.contribute_lyrics_1}
              />
            </Col>
          </Row>
          <div>
            <GoogleAd
              client={GoogleAdSense.client}
              slot={GoogleAdSense.slot.contribute_lyrics_2}
            />
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default AddNewSongLyricsPage;
