// src/layouts/MainLayout.js
import React from "react";
import { Col, Row } from "react-bootstrap";

import AppFooter from "../components/AppFooter/AppFooter";
import styles from "./MainLayout.module.css";
import AppNavigation from "../components/AppNavigation/AppNavigation.jsx";

const MainLayout = ({ children }) => {
  return (
    <>
      <main>
        <Row className="m-0 p-0">
          <Col className={`m-0 p-0 ${styles["col-12-5"]}`}></Col>
          <Col className={`m-0 p-0 ${styles["col-75"]}`}>
            <div className="m-0">
              <div className="m-2">
                <AppNavigation />
              </div>
              {children}
            </div>
          </Col>
          <Col className={`m-0 p-0 ${styles["col-12-5"]}`}></Col>
        </Row>
      </main>
      <AppFooter />
    </>
  );
};

export default MainLayout;
