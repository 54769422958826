import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button as RButton } from "react-bootstrap";
import { FaPlay } from "react-icons/fa6";

import MainLayout from "../../../layouts/MainLayout";
import { GoogleAdSense } from "../../../utils/constants";

import MostPopularSongs from "../../../components/_sections/MostPopularSongs";
import TrendingPlaylists from "../../../components/_sections/TrendingPlaylists";
import TrendingArtists from "../../../components/_sections/TrendingArtists";
import TopExploreAlbums from "../../../components/_sections/TopExploreAlbums";
import MusicMasterQuiz from "../../../components/_sections/MusicMasterQuiz";
import RecommendedSongs from "../../../components/_sections/RecommendedSongs";
import FeaturedMusicVideos from "../../../components/_sections/FeaturedMusicVideo";
import LatestMusicNews from "../../../components/_sections/LatestMusicNews";
import FollowUs from "../../../components/_sections/FollowUs";
import TrendingPodcasts from "../../../components/_sections/TrendingPodcasts";
import TopBar from "../../../components/_buttons/TopBar";
import GoogleAd from "../../../components/Advertisements/GoogleAd";
import { getConfig, handleApiError } from "../../../services/apiUtils";
import { controller } from "../../../services/apiServices";

function HomePage() {
  const [bannerHome, setBannerHome] = useState(null);

  const fetchBannerHome = async () => {
    try {
      let payload = { genre: "All" };
      const response = await controller.config.getBannerImage(
        payload,
        getConfig()
      );

      if (response && response.status === 200 && response.data) {
        setBannerHome(response.data);
      } else {
        console.error("Invalid response:", response);
      }
    } catch (error) {
      console.error("Error fetching banner image:", error);
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchBannerHome();
  }, []);

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_1}
          />
        </div>

        <div className="m-4 m-lg-2">
          <Row className="align-items-stretch">
            {" "}
            {/* Aligns columns to stretch the height */}
            <Col lg={8} md={8} sm={12} xs={12} className="d-flex flex-column">
              <div
                style={{
                  position: "relative", // Ensure absolute positioning of image inside
                  width: "100%",
                  paddingTop: "56.25%", // 16:9 aspect ratio (height is 56.25% of the width)
                  backgroundImage: `url(${bannerHome?.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat", // Ensure image doesn't repeat if it's smaller than container
                  flex: 1, // Make sure it takes the available space in its column
                }}
                className="rounded-4 p-5"
              >
                <div
                  className="d-flex flex-column justify-content-end"
                  style={{ height: "100%" }}
                >
                  <Row className="d-flex align-items-center">
                    <Col lg={8} md={8} sm={12} xs={12} className="d-flex">
                      <h1 className="text-white fw-bold">
                        {bannerHome?.description}
                      </h1>
                    </Col>
                  </Row>
                  <a
                    href={bannerHome?.redirection_link}
                    target="_blank"
                    className="w-100"
                  >
                    <RButton variant="eigengrau" className="mt-3 p-3 w-100">
                      <Row className="d-flex align-items-center">
                        <Col lg={6} md={6} sm={12} xs={12} className="d-flex">
                          <RButton
                            variant="eigengrau"
                            className="ms-2 d-flex align-items-center"
                          >
                            <FaPlay />
                          </RButton>
                          <small className="text-white mb-0 text-wrap">
                            {bannerHome?.description}
                          </small>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}></Col>
                      </Row>
                    </RButton>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="d-flex flex-column">
              <MostPopularSongs
                display={6}
                heading={6}
                viewMode="list"
                style={{ flex: 1 }}
              />
            </Col>
          </Row>
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_2}
          />
        </div>

        <div className="m-4 m-lg-2">
          <TrendingPlaylists />
        </div>

        <div className="m-0 m-lg-2">
          <TrendingArtists mode="dark" />
        </div>

        <div className="m-4 m-lg-2">
          <TopExploreAlbums />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_3}
          />
        </div>

        <div>
          <MusicMasterQuiz />
        </div>

        <div>
          <RecommendedSongs />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_4}
          />
        </div>

        <div className="m-4 m-lg-2">
          <FeaturedMusicVideos />
        </div>

        <div>
          <TrendingPodcasts />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_5}
          />
        </div>

        <div className="m-4 m-lg-2">
          <LatestMusicNews />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_6}
          />
        </div>

        <div className="m-4 m-lg-2">
          <FollowUs />
        </div>

        <div>
          <GoogleAd
            client={GoogleAdSense.client}
            slot={GoogleAdSense.slot.home_7}
          />
        </div>
      </Container>
    </MainLayout>
  );
}

export default HomePage;
