import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import _ from "lodash";

import NewsCard from "../_cards/NewsCard";
import { ROUTES } from "../../routes/constants";
import { getConfig } from "../../services/apiUtils";
import { newsController } from "../../features/news/newsSlice";

const LatestMusicNews = ({ genreType = "all", display = 4 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    newsList = [],
    isLoading,
    error,
  } = useSelector((state) => state.news || {});

  const validDisplay = Number.isInteger(display) && display > 0 ? display : 4;

  const displayedNews = useMemo(() => {
    return Array.isArray(newsList)
      ? [...newsList]
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, validDisplay)
      : [];
  }, [newsList, validDisplay]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        await dispatch(
          newsController.getNewsList({
            payload: genreType !== "all" ? { genre: genreType } : {},
            config: getConfig(),
          })
        );
      } catch (fetchError) {
        console.error("Failed to fetch news:", fetchError);
      }
    };

    fetchNews();
  }, [dispatch]);

  return (
    <div className="my-4">
      <div className="d-flex align-items-center justify-content-between my-3">
        <h1 className="fw-bold">
          Latest {genreType !== "all" ? _.capitalize(genreType) : ""} Music News
        </h1>
        {location.pathname === "/" && (
          <div className="d-flex align-items-center d-none d-lg-block d-md-block">
            <span className="fw-bold">See All</span>
            <Button
              variant="eigengrau"
              size="sm"
              className="fw-bold fs-6 mx-2 rounded-circle"
              onClick={() => navigate(ROUTES.MAIN.NEWS)}
            >
              <MdArrowOutward />
            </Button>
          </div>
        )}
      </div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "25vh", width: "100%" }}
        >
          <Spin tip="Loading..." size="large" />
        </div>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          Failed to load news. Please try again later.
        </div>
      ) : displayedNews.length > 0 ? (
        <>
          {/* <div
            className={`d-flex overflow-x-scroll ${styles.horizontalScroll}`}
          >
            {displayedNews.map((news, index) => (
              <NewsCard key={news.id || index} news={news} index={index} />
            ))}
            <div className="d-block d-sm-none w-100">
              <div className="d-flex align-items-center">
                <span className="fw-bold">See All</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                  onClick={() => navigate(ROUTES.MAIN.ALBUMS)}
                >
                  <MdArrowOutward />
                </Button>
              </div>
            </div>
          </div> */}
          <Row className="m-0 p-0 g-4">
            {displayedNews.map((news, index) => (
              <Col lg={3} md={4} sm={12} xs={12} key={index}>
                <NewsCard key={news.id || index} news={news} index={index} />
              </Col>
            ))}
            {location.pathname !== "/" && (
              <div className="d-flex align-items-center">
                <span className="fw-bold">See More</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                  onClick={() => navigate(ROUTES.MAIN.NEWS)}
                >
                  <MdArrowOutward />
                </Button>
              </div>
            )}
          </Row>
        </>
      ) : (
        <div className="text-center text-muted">
          No music news available at the moment.
        </div>
      )}
    </div>
  );
};

// PropTypes for type safety
LatestMusicNews.propTypes = {
  genreType: PropTypes.string,
  display: PropTypes.number,
};

export default LatestMusicNews;
