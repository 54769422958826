import React from "react";
import { Badge, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaFire } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { StaticImages } from "../../utils/constants";
import styles from "./ArtistCard.module.css";
import _ from "lodash";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

const ArtistCard = ({ viewMode = "card", artist, index }) => {
  const navigate = useNavigate();

  // Default artist values to handle missing data
  const artistName = artist?.artist_name || `Artist ${index + 1}`;
  const artistImage =
    artist?.downloaded_image_link || StaticImages.App.AppDefaultS;

  // Navigation handler
  const handleNavigate = () => {
    navigate(
      generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
        artistName: _.kebabCase(artistName),
        artistId: artist?.artist_id,
      })
    );
  };

  // Badge View
  const renderBadgeView = () => (
    <motion.div
      whileHover={{
        backgroundColor: "#FF4163",
        color: "white",
        transition: { duration: 0.3 },
      }}
      className="p-3 rounded-4 text-center position-relative border"
      style={{ maxWidth: "250px" }}
      onClick={handleNavigate}
    >
      <div className="position-relative">
        {/* Artist Image */}
        <Image
          src={artistImage}
          alt={`Artist ${artistName}`}
          roundedCircle
          className="shadow"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
          }}
          loading="lazy"
        />

        {/* Badge Icon */}
        <Badge
          pill
          className="bg-hotPink position-absolute top-0 end-0 rounded-circle"
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
          }}
        >
          <FaFire />
        </Badge>
      </div>

      <OverlayTrigger placement="top" overlay={<Tooltip>{artistName}</Tooltip>}>
        <p
          className="fw-bold mt-3 mb-0"
          style={{ cursor: "pointer" }}
          onClick={handleNavigate}
        >
          {artistName}
        </p>
      </OverlayTrigger>
    </motion.div>
  );

  // Card View
  const renderCardView = () => (
    <motion.div
      whileHover={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        transition: { duration: 0.3 },
      }}
      className={`position-relative rounded-4 ${styles.artistCard}`}
      onClick={handleNavigate}
    >
      {/* Artist Image */}
      <Image
        src={artistImage}
        alt={`Artist ${artistName}`}
        className={`w-100 h-100 ${styles.artistImage}`}
        loading="lazy"
      />

      {/* Overlay Tooltip */}
      <OverlayTrigger placement="top" overlay={<Tooltip>{artistName}</Tooltip>}>
        <p
          className={`text-center fw-semibold text-white ${styles.artistName}`}
          style={{ cursor: "pointer" }}
          onClick={handleNavigate}
        >
          {artistName}
        </p>
      </OverlayTrigger>
    </motion.div>
  );

  return viewMode === "badge" ? renderBadgeView() : renderCardView();
};

export default ArtistCard;
