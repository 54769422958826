import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

import { artistController } from "../../features/artist/artistSlice";
import { getConfig } from "../../services/apiUtils";
import ArtistCard from "../_cards/ArtistCard";
import { ErrorAlert } from "../_alerts/UIAlerts";

function TopArtists({ display = 4, genreType = "all" }) {
  const dispatch = useDispatch();

  const { topArtists, isLoading, error } = useSelector(
    (state) => state.artist || {}
  );

  useEffect(() => {
    dispatch(
      artistController.getTopArtists({
        payload: genreType !== "all" ? { genre: genreType } : {},
        config: getConfig(),
      })
    ).catch((error) => console.error("Failed to fetch artists:", error));
  }, [dispatch, genreType]);

  const randomArtists = useMemo(() => {
    return topArtists?.length > 0
      ? [...topArtists].sort(() => Math.random() - 0.5).slice(0, display)
      : [];
  }, [topArtists, display]);

  return (
    <div className="my-1 my-lg-4">
      <div className="my-1 my-lg-4">
        <h1 className="fw-bold">Top Artists</h1>

        <Row className="g-3 g-lg-4 m-0 p-0">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "25vh", width: "100%" }}
            >
              <Spin tip="Loading..." size="large" />
            </div>
          ) : error ? (
            <>
              <ErrorAlert description={error} />
            </>
          ) : randomArtists.length > 0 ? (
            <Row className="g-3 g-lg-4 bg-ghostWhite m-0 p-0">
              {randomArtists.map((artist, index) => (
                <>
                  <Col lg={3} md={4} sm={12} xs={12} key={index}>
                    <ArtistCard artist={artist} index={index} />
                  </Col>
                </>
              ))}

              {/* <div className="ms-auto d-flex align-items-center float-end">
                <span className="fw-bold">See All</span>
                <Button
                  variant="eigengrau"
                  size="sm"
                  className="fw-bold fs-6 mx-2 rounded-circle"
                >
                  <MdArrowOutward />
                </Button>
              </div> */}
            </Row>
          ) : (
            <p className="text-center">
              No top artists available at the moment.
            </p>
          )}
        </Row>
      </div>
    </div>
  );
}

export default TopArtists;
