import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import PlaylistSongsSection from "../../../components/_sections/PlaylistSongsSection";
import { playlistController } from "../../../features/playlist/playlistSlice";
import { getConfig } from "../../../services/apiUtils";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function PlaylistSongsPage() {
  const dispatch = useDispatch();
  const { playlistId, playlistName } = useParams();

  const { playlistSongs, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        await dispatch(
          playlistController.getPlaylistSongs({
            payload: { playlist_id: playlistId },
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch playlists songs:", error);
      }
    };

    fetchPlaylists();
  }, [dispatch, playlistId]);

  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-4 m-lg-2">
            <div className="my-2">
              <div
                style={{
                  position: "relative", // Ensure absolute positioning of image inside
                  width: "100%",
                  paddingTop: "56.25%", // 16:9 aspect ratio (height is 56.25% of the width)
                  backgroundImage: `url(${StaticImages.Pages.PlaylistCover})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat", // Ensure image doesn't repeat if it's smaller than container
                  flex: 1, // Make sure it takes the available space in its column
                }}
                className="rounded-4 p-4"
              >
                <h1 className="fw-bold text-white pt-5 mt-5">
                  <b>{playlistSongs?.playlist_name}</b>
                </h1>
              </div>
              {/* <Image
                fluid
                src={
                  StaticImages.Pages.PlaylistCover ||
                  StaticImages.Pages.PlaylistCover
                }
                width={"100%"}
                className="rounded-4"
                alt="Home Banner"
                loading="lazy"
              /> */}
            </div>
            <Row>
              <Col lg={8} md={8} sm={12} xs={12}>
                <PlaylistSongsSection
                  playlistSongs={playlistSongs}
                  isLoading={isLoading}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div>
                  <GoogleAd
                    client={GoogleAdSense.client}
                    slot={GoogleAdSense.slot.playlist_songs_1_v}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </MainLayout>
    </>
  );
}

export default PlaylistSongsPage;
