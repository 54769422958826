import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getSongLyrics = createApiThunk(
  "lyrics/getSongLyrics",
  { method: "POST", endpoint: "/user/get-lyrics" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  },
  (error) => {
    // Optional: Handle failure (e.g., toast.error)
  }
);

export const getSongLyricsMeaning = createApiThunk(
  "lyrics/getSongLyricsMeaning",
  { method: "POST", endpoint: "/user/song-meaning" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  },
  (error) => {
    // Optional: Handle failure (e.g., toast.error)
  }
);

export const getMyLyricsSongs = createApiThunk(
  "lyrics/getMyLyricsSongs",
  { method: "GET", endpoint: "/user/all-favourite-songs" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  },
  (error) => {
    // Optional: Handle failure (e.g., toast.error)
  }
);

export const getSongComments = createApiThunk(
  "lyrics/getSongComments",
  { method: "POST", endpoint: "/user/get-comments" },
  ({ data }) => {
    // showToast("success", data.message, 2);  // Optional: Handle success (e.g., toast.success)
  },
  (error) => {
    // Optional: Handle failure (e.g., toast.error)
  }
);

export const postSongComment = createApiThunk(
  "lyrics/postSongComment",
  { method: "POST", endpoint: "/user/post-comment" },
  ({ data }) => {
    // showToast("success", data.message, 2);  // Optional: Handle success (e.g., toast.success)
  },
  (error) => {
    // Optional: Handle failure (e.g., toast.error)
  }
);

export const lyricsThunks = {
  getSongLyrics,
  getSongLyricsMeaning,
  getMyLyricsSongs,
  // comment
  getSongComments,
  postSongComment,
};
