import React from "react";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";

const QuizCard = () => {
  return (
    <div>
      <Card className="bg-corbeau rounded-4 text-white">
        <Button className="bg-hotPink border-0 rounded-2 position-absolute m-3">
          <FaTelegramPlane size={20} color="white" className="me-2" />
          <span className="text-light">Share</span>
        </Button>
        <Card.Body className="mt-5">
          <Card.Title style={{ fontSize: "24px" }} className="mt-3 fw-bold">
            The Lyrics Quiz
          </Card.Title>
          <Card.Text className="mb-5">
            Are you a music master? Dare to take the ultimate music challenge?
            Don’t be so shy — Our Fun Music Quiz presents questions and answers
            on old and modern music to test your knowledge.
          </Card.Text>
          <div
            style={{ marginTop: "20px" }}
            className="d-flex justify-content-between"
          >
            <span style={{ fontSize: "14px" }}>
              Sound: <HiMiniSpeakerWave size={20} />
            </span>
            <span style={{ fontSize: "14px" }}>Time: 26:35</span>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default QuizCard;
