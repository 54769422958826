import React from "react";
import { Col, Container, Row, Button as BsButton } from "react-bootstrap";
import {
  footerNavLinks,
  letterNavLinks,
  StaticLinks,
} from "../../utils/constants";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa6";
import styles from "./AppFooter.module.css";
import { NavLink } from "react-router-dom";
import AlphabetNav from "../_buttons/AlphabetNav";

export default function AppFooter() {
  const socialMediaLinks = [
    { icon: FaPinterest, label: "Pinterest", color: "#E60023", path: "" },
    { icon: FaYoutube, label: "Youtube", color: "#FF0000", path: "" },
    {
      icon: FaInstagram,
      label: "Instagram",
      color: "#E1306C",
      path: StaticLinks.App.AppInstagramLink,
    },
    {
      icon: FaFacebook,
      label: "Facebook",
      color: "#3b5998",
      path: StaticLinks.App.AppFacebookLink,
    },
  ];

  return (
    <Container fluid className={`${styles.AppFooter} fw-semibold`}>
      <hr />

      {/* <Container fluid className="mx-auto">
        <div className="mx-auto d-none d-lg-flex flex-wrap">
          {letterNavLinks.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={styles.navLinkAlphabet}
              style={{ whiteSpace: "nowrap" }}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </Container> */}
      <Container className="py-4 fl w-75 mx-auto">
        <AlphabetNav />
      </Container>

      <hr />

      <Row className="m-4 p-lg-5 p-sm-1">
        {footerNavLinks.map((section, sectionIndex) => (
          <Col
            lg={3}
            md={3}
            sm={6}
            xs={6}
            key={sectionIndex}
            className={`mb-4`}
          >
            <h5 className={`mb-5 fw-bold`}>{section.mainHeading}</h5>
            <ul className={`list-unstyled`}>
              {section.subLinks.map((link, linkIndex) => (
                <li
                  key={linkIndex}
                  className={`fw-normal ${styles.footerLink}`}
                >
                  <NavLink to={link.path} className={`${styles.footerLink}`}>
                    {link.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </Col>
        ))}
        <Col lg={3} md={3} sm={6} xs={6}>
          <div>
            <h5 className="mb-5 fw-bold">Follow Us</h5>
            <div className="d-flex flex-column-reverse align-items-start gap-3">
              {socialMediaLinks.map((social, index) => (
                <BsButton
                  key={index}
                  variant="whiteBtn"
                  className="rounded-3 w-100"
                  onClick={() => window.open(social.path, "_blank")}
                >
                  <div className="d-flex justify-content-between align-items-center p-1">
                    <small className="fw-bold">{social.label}</small>
                    <social.icon
                      className="fs-4"
                      style={{ color: social.color }}
                    />
                  </div>
                </BsButton>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <hr />

      <div className="py-3">
        <Row className="mx-5 g-4 text-center">
          <Col lg={8} md={8} sm={12} xs={12}>
            <div className="fw-normal">
              All rights reserved to Global Media Holdings Ltd. 2024
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="d-flex justify-content-evenly">
              <NavLink
                to={"/terms-of-use"}
                className={`${styles.footerLink} mx-2 fw-normal`}
              >
                Terms of Use
              </NavLink>
              <NavLink
                to={"/privacy-policy"}
                className={`${styles.footerLink} mx-2 fw-normal`}
              >
                Privacy Policy
              </NavLink>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
