// src/routes/authRoutes.js

import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constants";

/* Auth Pages */
import ProtectedRoute from "./ProtectedRoute";

import LoginPage from "../pages/auth/LoginPage/LoginPage";
import SignupPage from "../pages/auth/SignupPage/SignupPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage/ResetPasswordPage";
import VerifyUserPage from "../pages/auth/VerifyUserPage/VerifyUserPage";
import ProfilePage from "../pages/auth/ProfilePage/ProfilePage";
import ProfilePreviewPage from "../pages/auth/ProfilePreviewPage/ProfilePreviewPage";
import GoogleAuthCallback from "../pages/auth/GoogleAuthCallback/GoogleAuthCallback";

const authRoutes = [
  <Route
    key="login"
    path={ROUTES.AUTH.LOGIN}
    element={<ProtectedRoute type="public" element={LoginPage} />}
  />,
  <Route
    key="google-callback"
    path="/auth/google/callback"
    type="public"
    element={<GoogleAuthCallback />}
  />,

  <Route
    key="signup"
    path={ROUTES.AUTH.SIGNUP}
    element={<ProtectedRoute type="public" element={SignupPage} />}
  />,
  <Route
    key="verify"
    path={ROUTES.AUTH.VERIFY}
    element={<ProtectedRoute type="public" element={VerifyUserPage} />}
  />,
  <Route
    key="forgot-password"
    path={ROUTES.AUTH.FORGOT_PASSWORD}
    element={<ProtectedRoute type="public" element={ForgotPasswordPage} />}
  />,
  <Route
    key="reset-password"
    path={ROUTES.AUTH.RESET_PASSWORD}
    element={<ProtectedRoute type="public" element={ResetPasswordPage} />}
  />,
  <Route
    key="profile"
    path={ROUTES.AUTH.PROFILE}
    element={<ProtectedRoute type="private" element={ProfilePage} />}
  />,
  <Route
    key="profile"
    path={ROUTES.AUTH.PROFILE_PREVIEW}
    element={<ProtectedRoute type="private" element={ProfilePreviewPage} />}
  />,
];

export default authRoutes;
