import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { toast } from "react-toastify";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { GoogleAdSense, StaticImages } from "../../../utils/constants";
import PlaylistSongsSection from "../../../components/_sections/PlaylistSongsSection";
import { playlistController } from "../../../features/playlist/playlistSlice";
import { getAuthConfig } from "../../../services/apiUtils";
import AddSongUserPlaylistModal from "../../../components/_modals/AddSongUserPlaylistModal";
import { controller } from "../../../services/apiServices";
import { showToast } from "../../../components/_toasts/notificationUtils";
import GoogleAd from "../../../components/Advertisements/GoogleAd";

function UserPlaylistSongsPage() {
  const dispatch = useDispatch();
  const { playlistId, playlistName } = useParams();

  const { userPlaylistSongs, isLoading, error } = useSelector(
    (state) => state.playlist
  );

  // Inside the component
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleAddSong = async (songId, playlistId) => {
    // setIsLoading(true);
    try {
      const response = await controller.playlist.addSongUserPlayList(
        {
          playlist_id: playlistId,
          song_id: songId.toString(),
        },
        getAuthConfig()
      );
      if (response.status === 201) {
        toast.success(response.message);
        fetchPlaylists();
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Failed to fetch search results:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchPlaylists = async () => {
    try {
      await dispatch(
        playlistController.getUserPlaylistSongs({
          payload: { playlist_id: playlistId },
          config: getAuthConfig(),
        })
      ).unwrap();
    } catch (error) {
      console.error("Failed to fetch playlists songs:", error);
    }
  };

  const handleDeleteSong = async (songId) => {
    try {
      const response = await controller.playlist.deleteSongUserPlayList(
        {
          playlist_id: playlistId,
          song_id: songId.toString(),
        },
        getAuthConfig()
      );
      if (response.success) {
        toast.success(response.message);
        fetchPlaylists();
        setIsModalVisible(false);
        // setUserPlaylistSongs((prevSongs) =>
        //   prevSongs.song_data.filter((song) => song.id !== songId)
        // );
      }
      showToast("success", "Song removed from playlist.");
    } catch (error) {
      console.error("Error deleting song:", error);
      showToast("error", "Failed to remove song.");
    }
  };

  useEffect(() => {
    fetchPlaylists();
  }, [dispatch, playlistId]);

  return (
    <>
      <MainLayout>
        <Container fluid className="m-0 p-0">
          <div className="m-2">
            <TopBar />
          </div>

          <div className="m-4 m-lg-2">
            <div className="my-2">
              <div
                style={{
                  position: "relative", // Ensure absolute positioning of image inside
                  width: "100%",
                  paddingTop: "56.25%", // 16:9 aspect ratio (height is 56.25% of the width)
                  backgroundImage: `url(${StaticImages.Pages.PlaylistCover})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat", // Ensure image doesn't repeat if it's smaller than container
                  flex: 1, // Make sure it takes the available space in its column
                }}
                className="rounded-4 p-4"
              >
                <h1 className="fw-bold text-white pt-5 mt-5">
                  <b>{userPlaylistSongs?.playlist_name}</b>
                </h1>
              </div>
            </div>
            <div className="m-4">
              <Button
                variant="solid"
                color="default"
                onClick={() => setIsModalVisible(true)}
              >
                Add Songs
              </Button>
            </div>
            <Row>
              <Col lg={8} md={8} sm={12} xs={12}>
                <PlaylistSongsSection
                  playlistSongs={userPlaylistSongs}
                  isLoading={isLoading}
                  onDeleteSong={handleDeleteSong}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <div>
                  <GoogleAd
                    client={GoogleAdSense.client}
                    slot={GoogleAdSense.slot.user_playlists_1_v}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <AddSongUserPlaylistModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAdd={handleAddSong}
        />
      </MainLayout>
    </>
  );
}

export default UserPlaylistSongsPage;
